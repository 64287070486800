import { createContext, useContext } from "react";
import { IGetTicketByUserUsecase } from "./domain/usecases/get_ticket_by_user_usecase";

export const GetTicketByIdContext = createContext<IGetTicketByUserUsecase | undefined>(undefined);

export function useGetTicketByIdUsecase(): IGetTicketByUserUsecase {
    const usecase = useContext(GetTicketByIdContext);
    if (!usecase) {
        throw new Error("useGetUserByIdUsecase must be used within a GetUserByIdContext.Provider");
    }
    return usecase;
}
