import Button, { ButtonProps } from "./button";

interface SecondaryButtonProps extends ButtonProps {}

export default function SecondaryButton({
  id,
  type,
  text,
  showIconRight,
  iconRight,
  enabled = true,
  color,
  infinity = false,
  onClick,
}: SecondaryButtonProps) {
  return (
    <Button
      id={id}
      type={type}
      backgroundColor="bg-secondary"
      disabledBackgroundColor="bg-on-secondary"
      disabledColor="text-on-primary"
      color={color}
      onClick={onClick}
      enabled={enabled}
      text={text}
      showIconRight={showIconRight}
      infinity={infinity}
      iconRight={iconRight}
    />
  );
}
