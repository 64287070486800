import { useFormik } from "formik";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useGetPhoneInfoByOrderContext } from "../../get_phone_info_by_order_context";

import { useAuthentication } from "features/authentication/authentication_bindings";
import OrderEntity from "features/purchase/domain/entities/order_entity";
import PhoneInfoEntity from "features/purchase/domain/entities/phone_info_entity";
import { useGetLastOrderByUserContext } from "features/purchase/get_last_order_by_user_context";
import { useGetUserByIdUsecase } from "features/purchase/get_user_by_id_context";
import * as Yup from 'yup';

export function useProfilePhoneInfoController() {
  const { currentUser } = useAuthentication();

  const [orderData, setOrderData] = useState<OrderEntity | null>(null)
  const [phoneData, setPhoneData] = useState<PhoneInfoEntity | null>(null)
  const [userData, setUserData] = useState<unknown>()

  const [isLoading, setIsLoading] = useState(false)

  const getLastOrderUsecase = useGetLastOrderByUserContext()
  const getPhoneInfoUsecase = useGetPhoneInfoByOrderContext()
  const getUserById = useGetUserByIdUsecase();

  const form = useFormik({
    initialValues: {
      phone: '',
      imei: '',
      brand: '',
      model: ''
    },
    validationSchema: Yup.object().shape({
      phone: Yup.string(),
      imei: Yup.string(),
      brand: Yup.string(),
      model: Yup.string()
    }),
    onSubmit: () => { }
  })

  useLayoutEffect(() => {
    setIsLoading(true)
    getOrderId()
  }, [])

  useEffect(() => {
    if (orderData) getPhoneInfo(orderData?.id)
  }, [orderData])

  useEffect(() => {
    if (phoneData) form.setValues(phoneData)
  }, [phoneData])

  const getOrderId = useCallback(async () => {
    await getLastOrderUsecase
      .execute()
      .then((order) => {
        setOrderData(new OrderEntity({
          auth_id: order.auth_id,
          brand_name: order.brand_name,
          created_at: order.created_at,
          end_validity: order.end_validity,
          id: order.id,
          imei: order.imei,
          inspection_url: order.inspection_url,
          manual_url: order.manual_url,
          model_name: order.model_name,
          msisdn: order.msisdn,
          policy_number: order.policy_number,
          policy_url: order.policy_url,
          product_name: order.product_name,
          score: order.score,
          start_validity: order.start_validity,
          state: order.state,
          subscription: order.subscription,
          update_at: order.update_at
        }))
      })
      .catch((err) => {
        setIsLoading(false)
      })
  }, [])

  const getUserData = async () => {
    const uid = currentUser?.uid ?? ''
    await getUserById.execute(uid).then((user) => setUserData(user)).catch(() => {
      setIsLoading(false)
    })

  }

  const getPhoneInfo = useCallback(async (id: number) => {
    getUserData()
    await getPhoneInfoUsecase
      .execute(String(id))
      .then((order) => {
        setPhoneData(new PhoneInfoEntity(
          order.msisdn.substring(2),
          order.imei,
          order.brand_name,
          order.model_name
        ))
      })
      .catch((err) => {
        console.log('Erro', err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  return {
    phoneData,
    orderData,
    isLoading,
    form
  }
}
