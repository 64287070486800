import { IHttpClient } from "core/protocols/http_client";
import ProfileEntity from "../entities/profile_entity";


export type UpdateProfileInput = {
  authMethod: string,
  authUid: string,
  fullname?: string,
  profile: ProfileEntity,
}

export type UpdateProfileOutput = {
  cpf: string,
  phone: string,
  state: string,
  fullname: string,
  have_ticket: boolean,
  updateAt: string,
  customer: {
    address: {
      city: string,
      complement: string,
      neighborhood: string,
      name: string,
      number: string,
      state: string,
      zipcode: string,
    },
    cpf: string,
    first_name: string,
    last_name: string,
    phone_number: string,
  }
}

export interface IUpdateProfileUsecase {
  execute(args: UpdateProfileInput): Promise<UpdateProfileOutput>;
}

export default class UpdateProfileUsecaseImpl implements IUpdateProfileUsecase {
  private httpClient: IHttpClient;

  constructor(httpClient: IHttpClient) {
    this.httpClient = httpClient;
  }

  private createPayload(args: UpdateProfileInput) {
    const entity = args.profile;
    const [first_name, last_name] = args.fullname?.split(' ') || [];
    return {
      auth_method: args.authMethod,
      auth_uid: args.authUid,
      cpf: entity.cpf,
      phone: entity.phone.replace(/\D/g, ''),
      state: "logged",
      fullname: args.fullname,
      have_ticket: false,
      updateAt: new Date().toISOString(),
      customer: {
        address: {
          city: entity.address.localidade,
          complement: entity.address.complemento,
          neighborhood: entity.address.bairro,
          name: entity.address.logradouro,
          number: entity.address.numero !== '' ? entity.address.numero : '',
          state: entity.address.uf,
          zipcode: entity.address.cep
        },
        cpf: entity.cpf,
        first_name: first_name || '',
        last_name: last_name || '',
        phone_number: entity.phone,
      }
    }
  }

  async execute(args: UpdateProfileInput): Promise<UpdateProfileOutput> {
    try {
      const payload = this.createPayload(args);
      console.log('payload', payload)
      const response = await this.httpClient.put<UpdateProfileOutput>('/bff/v1/user', payload)
      return response.data
    } catch (error) {
      throw error;
    }
  }
}