import { useEffect, useState } from 'react'
import Header from './components/Header'
import Footer from './components/Footer'
import Loader from './components/Loader'
import FormCheckout from './components/FormCheckout'
import Decode from './services/JWT'
import Failed from './Failed'
// import { Sales, ticket } from '../../services/Backend'
import { Sales } from './services/Backend'

const CheckoutApp = () => {
    const infoDecode = Decode()
    const [loader, setLoader] = useState(false)
    const [statusSales, setStatusSales] = useState(null)
    const [statusPayment, setStatusPayment] = useState(null)

    const dataUrl = () => {
        // Captura o parâmetro data da URL
        let url_string = window.location.href;
        let url = new URL(url_string);
        const param = url.searchParams.get("data")

        return param !== null ? param : false
    }

    // console.log('Informações PayLoad::: ', infoDecode)

    const sendSales = async (data) => {
        const dataSales = await Sales(data)

        if (dataSales.status) {
            setStatusPayment('payment_pending')
            setLoader(false)
        } else {
            setStatusSales(false)
            setLoader(false)
        }
    }

    const formatInvoiceDate = (e) => {
        const dateString = e;
        const date = new Date(dateString);

        const dia = date.getDate();
        const mes = date.getMonth() + 1;
        const ano = date.getFullYear();

        return `${ano}-${mes}-${dia}`;
    }

    const childToParent = (childdata) => {
        // Recupera os dados do formulário
        setLoader(true)
        let arrName = childdata.creditCardUserName.split(' ')
        let arrLastName = childdata.creditCardUserName.split(' ')
        arrLastName.shift()
        const firstName = arrName[0];
        const lastName = arrLastName.toString().replace(',', ' ');
        const month = splitStringDate(childdata.creditCardDateValid)[1]
        const year = splitStringDate(childdata.creditCardDateValid)[0]

        const newData = {
            creditCardNumber: childdata.creditCardNumber,
            creditCardFirstName: firstName,
            creditCardLastName: lastName,
            creditCardMonth: month,
            creditCardYear: year,
            creditCardCVV: childdata.creditCardCVV
        }

        // useEffect(
        sendSales({
            "auth_uid": infoDecode.auth_uid,
            "mobile_number": infoDecode.customer.mobile_number,
            "invoice_date": formatInvoiceDate(infoDecode.invoice_date),
            // "product": {
            //     "id": infoDecode.product.id,
            //     "name": infoDecode.product.name,
            //     "amount": infoDecode.product.amount,
            //     "plan_code": infoDecode.product
            // },
            "product": infoDecode.product,
            "score": infoDecode.score !== null ? infoDecode.score : '',
            "state": "charge",
            "imei": infoDecode.imei,
            "customer": {
                "cpf": infoDecode.customer.cpf,
                "email": infoDecode.customer.email,
                "first_name": infoDecode.customer.first_name,
                "last_name": infoDecode.customer.last_name,
                "phone_number": infoDecode.customer.mobile_number,
                "birthday": infoDecode.customer.birthday,
                "address": {
                    "name": infoDecode.customer.address.name,
                    "city": infoDecode.customer.address.city,
                    "neighborhood": infoDecode.customer.address.neighborhood,
                    "number": infoDecode.customer.address.number,
                    "state": infoDecode.customer.address.state,
                    "zipcode": infoDecode.customer.address.zipcode,
                    "complemennt": infoDecode.customer.address.complement
                }
            },
            "payment": {
                "method": "subscription",
                "credit_card": {
                    "first_name": newData.creditCardFirstName === undefined ? '' : newData.creditCardFirstName,
                    "last_name": newData.creditCardLastName === undefined ? '' : newData.creditCardLastName,
                    "number": newData.creditCardNumber === undefined ? '' : newData.creditCardNumber,
                    "cvv": newData.creditCardCVV === undefined ? '' : newData.creditCardCVV,
                    "month": newData.creditCardMonth === undefined ? '' : newData.creditCardMonth,
                    "year": newData.creditCardYear === undefined ? '' : newData.creditCardYear
                    // "first_name": "John",
                    // "last_name": "Appleseed",
                    // "number": "4111111111111111",
                    // "cvv": "123",
                    // "month": "10",
                    // "year": "2025"
                }
            },
            "app_token": dataUrl()

        })
        // );
    }

    // const splitString = (name) => {
    //     return name !== undefined && name.split(' ')
    // }
    const splitStringDate = (date) => {
        return date !== undefined && date.split('-')
    }

    useEffect(() => {
        if (localStorage.getItem('success_payment') !== null) {
            window.location.href = "/success"
        }
    });

    if (loader) {
        return <Loader />
    }

    if (statusPayment === "active") {
        window.location.href = "/success"
    } else if (statusPayment === "payment_pending") {
        return (
            <div className="struct">
                <Header warning={true} text="Aguardando liberação de pagamento" />
                <Failed details='O seu pagamento está sendo processado, volte ao app e verifique o status em "Certificado de Seguro".' repeat_process={false} />
                <Footer />
            </div>
        )
    } else if (statusPayment !== null) {
        return (
            <div className="struct">
                <Header userName={`${infoDecode.customer.first_name} ${infoDecode.customer.last_name}`} error={true} text="não foi possível processar o pagamento." />
                <Failed details="Houve um erro ao processar a operação e sua assinatura não foi realizada. Tente novamente para finalizar o processo de proteção do seu celular." repeat_process={false} />
                <Footer />
            </div>
        )
    }

    if (statusSales === false) {
        return (
            // <div className="struct">
            //     <Header error={true} text='Erro genérico' />
            //     <Failed details="Desculpe, ocorreu um erro genérico, volte ao app e faça uma nova tentativa." repeat_process={false}/>
            //     <Footer />  
            // </div>

            <div className="struct">
                <Header userName={`${infoDecode.customer.first_name} ${infoDecode.customer.last_name}`} error={true} text="não foi possível processar o pagamento." />
                <Failed details="Houve um erro ao processar a operação e sua assinatura não foi realizada. Tente novamente para finalizar o processo de proteção do seu celular." repeat_process={false} />
                <Footer />
            </div>
        )
    }

    if (!infoDecode.status && infoDecode.status !== undefined) {
        return (
            <div className="struct">
                <Header error={infoDecode.error} text={infoDecode.data} />
                <Failed details={infoDecode.details} repeat_process={infoDecode.repeat_process} />
                <Footer />
            </div>
        )
    }

    return (
        <div className="struct">
            <Header userName={`${infoDecode.customer.first_name} ${infoDecode.customer.last_name}`} />
            <FormCheckout
                childToParent={childToParent}
                plan_name={infoDecode.product.name !== undefined ? infoDecode.product.name : ''}
                amount_plan={infoDecode.product.amount !== undefined ? infoDecode.product.amount.toString().replace(".", ",") : ''}
            />
            <Footer />
        </div>
    )
}

export default CheckoutApp