import { IconAmex, IconDiners, IconElo, IconMastercard, IconVisa, Text } from "gosafe";
import { FlagType } from "../checkout_controller";

interface CardProps {
    flag?: FlagType
    number?: string
    name?: string
    expiry?: string
    cvv?: string
}

function Card({ flag, number, name, expiry, cvv }: CardProps) {
    return (
        <div className="flex flex-col justify-between w-[336px] h-[205px] bg-gradient-to-r from-primary to-blue-dark rounded-3xl p-8">
            <div className="flex justify-end h-[32px]">
                {flag === 'visa' && <IconVisa />}
                {flag === 'mastercard' && <IconMastercard />}
                {flag === 'amex' && <IconAmex />}
                {flag === 'diners' && <IconDiners />}
                {flag === 'elo' && <IconElo />}
                {flag === 'jcb' && <img width={32} src="/images/jcb.svg" />}
                {flag === 'discover' && <img width={32} src="/images/discover.svg" />}
                {flag === undefined && <Text fontSize="text-2xl">*</Text>}
            </div>

            <div>
                {number && <Text color="text-neutral-100" fontSize="text-2xl">{number}</Text>}
                {!number && <Text color="text-neutral-100" fontSize="text-2xl">0000 0000 0000 0000</Text>}
            </div>
            <div className="flex gap-4 text-neutral-100">
                <div className="basis-3/5">
                    <div className="text-xs font-thin">Nome impresso</div>
                    <div className="text-sm">{name}</div>
                    {!name && <div className="text-sm">Nome impresso</div>}
                </div>
                <div className="basis-1/5">
                    <div className="flex flex-col items-center">
                        <div className="text-xs font-thin">Validade</div>
                        <div className="text-sm">{expiry}</div>
                        {!expiry && <div className="text-sm">MM/YY</div>}
                    </div>
                </div>
                <div className="basis-1/5">
                    <div className="flex flex-col items-end">
                        <div className="text-xs font-thin">CVV</div>
                        <div className="text-sm">{cvv}</div>
                        {!cvv && <div className="text-sm">000</div>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Card;