class ImeiCheckResultEntity {
  imei: string;
  brand: string;
  identifiers: string[];
  name: string;
  isValid: boolean = false;

  constructor(
    imei: string,
    brand: string,
    identifiers: string[],
    name: string,
    isValid: boolean = false
  ) {
    this.imei = imei;
    this.brand = brand;
    this.identifiers = identifiers;
    this.name = name;
    this.isValid = isValid;
  }
}

export default ImeiCheckResultEntity;
