import { IHttpClient } from "core/protocols/http_client";
import { DropdownFieldItem } from "gosafe";

import BrandAndModelSearchBindings from "./brand_and_model_search_bindings";
import BrandEntity from "./domain/entities/brand_entity";
import PhoneEntity from "./domain/entities/phone_entity";
import BrandAndModelForm from "./presenter/brand_and_model_form";

export { BrandEntity, PhoneEntity };

interface BrandAndModelSearchProps {
  httpClient: IHttpClient;
  onBrandChanged?: (value: DropdownFieldItem | undefined) => void;
  onModelChanged?: (value: DropdownFieldItem | undefined) => void;
  onTap?: (brand: BrandEntity, phone: PhoneEntity) => void;
}

export default function BrandAndModelSearch({
  httpClient,
  onBrandChanged,
  onModelChanged,
  onTap,
}: BrandAndModelSearchProps) {
  return (
    <BrandAndModelSearchBindings httpClient={httpClient}>
      <BrandAndModelForm
        onBrandChanged={onBrandChanged}
        onModelChanged={onModelChanged}
        onTap={onTap}
      />
    </BrandAndModelSearchBindings>
  );
}
