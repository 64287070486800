interface IconChatBubbleProps {
    width?: number
}

function IconChatBubble({ width = 20 }: IconChatBubbleProps) {

    return (
        <svg width={width} viewBox="0 0 35 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M4.91855 22.9987C4.44657 25.1049 3.45815 27.4667 1.29678 28.5791L1.28115 28.5875C0.957608 28.7378 0.78627 29.0985 0.878132 29.447C0.971076 29.8029 1.31024 30.0366 1.67475 29.9953C1.6831 29.9942 1.69037 29.9928 1.69765 29.9928C4.09205 29.6286 10.0445 28.2049 13.6086 22.999H31.903C32.7236 22.999 33.5119 22.672 34.0924 22.09C34.674 21.5066 35 20.7174 35 19.893V3.10729C35 2.28292 34.674 1.49257 34.0924 0.910289C33.5119 0.328008 32.7239 0 31.903 0H3.09695C2.2764 0 1.48813 0.328022 0.907582 0.910289C0.325957 1.49257 0 2.28292 0 3.10729V19.893C0 20.7174 0.325971 21.5066 0.907582 22.09C1.48813 22.6723 2.27613 22.999 3.09695 22.999L4.91855 22.9987ZM7.8442 8.32895C6.17883 8.32895 4.82697 9.68484 4.82697 11.3552C4.82697 13.0255 6.17883 14.3814 7.8442 14.3814C9.50957 14.3814 10.8614 13.0255 10.8614 11.3552C10.8614 9.68484 9.50957 8.32895 7.8442 8.32895ZM27.1545 8.32895C25.4891 8.32895 24.1372 9.68484 24.1372 11.3552C24.1372 13.0255 25.4891 14.3814 27.1545 14.3814C28.8198 14.3814 30.1717 13.0255 30.1717 11.3552C30.1717 9.68484 28.8198 8.32895 27.1545 8.32895ZM17.4993 8.32895C15.834 8.32895 14.4821 9.68484 14.4821 11.3552C14.4821 13.0255 15.834 14.3814 17.4993 14.3814C19.1647 14.3814 20.5166 13.0255 20.5166 11.3552C20.5166 9.68484 19.1647 8.32895 17.4993 8.32895Z" fill="currentColor" />
        </svg>
    )
}

export default IconChatBubble;
