import React from "react";
import ReactDOM from "react-dom/client";

import { SplashPage } from "pages/splash";
import { RecoilRoot } from "recoil";

import reportWebVitals from "./reportWebVitals";
import Routes from "./routes";

import "./assets/css/import.css";
import * as Sentry from "@sentry/react";
import { SentryContext } from "monitoring/sentry_context";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <SentryContext.Provider value={Sentry}>
    <RecoilRoot>
      <React.StrictMode>
        <SplashPage />
        <Routes />
      </React.StrictMode>
    </RecoilRoot>
  </SentryContext.Provider>
);

reportWebVitals();