import { TicketEntity } from "features/purchase/domain/entities/ticket_entity"
import { useGetTicketByIdUsecase } from "features/purchase/get_ticket_by_user_context"
import { useEffect, useState } from "react"
import * as Sentry from "@sentry/react";

export function useProfileCertificateController() {

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [certificates, setCertificates] = useState<TicketEntity[] | null | undefined>(null)

  const getTickets = useGetTicketByIdUsecase()

  useEffect(() => {
    getTickets.execute('')
      .then(value => {
        setCertificates(value)
      })
      .catch(error => {
        Sentry.captureException(error, {
          tags: {
            'feature.purchase.profile': "useProfileCertificateController",
          }
        });
      }).finally(() => {
        setIsLoading(false)
      })
  }, [certificates])


  return {
    isLoading,
    certificates
  }
}
