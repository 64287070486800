import { ReactNode } from "react";

import { FontSize, FontWeight, TextColor } from "gosafe/themes";

interface TextProps {
  id?: string;
  color?: TextColor;
  colorDark?: TextColor;
  fontWeight?: FontWeight;
  fontFamily?: string;
  fontSize?: FontSize;
  children: ReactNode;
}

function Text({
  color = "text-primary",
  fontSize = "text-base",
  fontFamily = "font-sans",
  fontWeight = "font-light",
  children,
  id,
}: TextProps) {
  const className = [color, fontWeight, fontFamily, fontSize];

  return <span id={id} className={className.join(" ")}>{children}</span>;
}

export default Text;
