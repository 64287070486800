import { useProfilePhoneInfoController } from "features/purchase/presenter/components/profile_phone_info_controller";
import { ExaLoading, InfinityLoading, TextField } from "gosafe";

export default function ProfilePhoneInfo() {



  const { form, isLoading } = useProfilePhoneInfoController()

  return (
    <div>
      {isLoading ?
        <div className="flex items-center justify-center p-12">
          <ExaLoading />
        </div> :
        <form className="flex flex-col gap-2">
          <div>
            <TextField
              name="phone"
              textLabel="Número"
              readOnly={true}
              hintText="00 00000-0000"
              mask={"99 99999-9999"}
              value={form.values.phone}
            />
          </div>
          <div>
            <TextField
              name="imei"
              textLabel="IMEI"
              readOnly={true}
              hintText="00 0000000 000000000"
              mask={"99 9999999 999999999"}
              value={form.values.imei}
            />
          </div>
          <div>
            <TextField
              name="brand"
              textLabel="Marca"
              readOnly={true}
              hintText="Marca"
              value={form.values.brand}
            />
          </div>
          <div>
            <TextField
              name="model"
              textLabel="Modelo"
              readOnly={true}
              hintText="Modelo"
              value={form.values.model}
            />
          </div>
        </form>}
    </div>
  );
}
