import forge from 'node-forge';

export interface IEncryptAesService {
  encrypt(args: { data: string, aesKey: string, initializationVector: string }): string;
  decryp(args: { encryptedData: string, aesKey: string, initializationVector: string }): string;
  generateKey(): { aesKey: string, initializationVector: string };
}

class EncryptAesServiceImpl implements IEncryptAesService {
  private readonly aesAlgorithm: any = 'AES-CBC';
  private  readonly enconding = 'raw';

  generateKey(): { aesKey: string; initializationVector: string; } {
    return {
      aesKey: forge.random.getBytesSync(32),
      initializationVector: forge.random.getBytesSync(16)
    }
  }

  encrypt(args: { data: string, aesKey: string, initializationVector: string }): string {
    const cipher = forge.cipher.createCipher(this.aesAlgorithm, args.aesKey);
    cipher.start({ iv: args.initializationVector });
    cipher.update(forge.util.createBuffer(args.data, this.enconding));
    cipher.finish();
    return forge.util.encode64(cipher.output.getBytes());
  }

  decryp(args: { encryptedData: string, aesKey: string, initializationVector: string }): string {
    const decipher = forge.cipher.createDecipher(this.aesAlgorithm, args.aesKey);
    decipher.start({ iv: args.initializationVector });
    decipher.update(forge.util.createBuffer(forge.util.decode64(args.encryptedData), this.enconding));
    decipher.finish();
    return decipher.output.getBytes();
  }
}

export default EncryptAesServiceImpl;