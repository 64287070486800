import { decompressFromEncodedURIComponent } from "lz-string";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ISignIn } from "../../../../core/authentication/sign_in";
import { UserInterface } from "../../../../core/authentication/users_interface";
import { useAuthentication } from "../../authentication_bindings";

function ReplaceAppState(): boolean {
  const {appState} = useParams();

  if (appState) {
    const decompressedAppState = decompressFromEncodedURIComponent(appState)
    history.replaceState(JSON.parse(decompressedAppState), '', `${window.location.origin}/signin`);
    return true;
  }
  return false;
}

export function useTesteFoneController({signWithGoogle, signWithApple, signWithEmailAndPassword}: {
  signWithGoogle: ISignIn,
  signWithApple: ISignIn,
  signWithEmailAndPassword: ISignIn;
}) {
  const stateReplaced = ReplaceAppState();
  const [showBackButton] = useState(!stateReplaced);
  const navigate = useNavigate();
  const {setAuthMethod} = useAuthentication();
  console.log(setAuthMethod)
  const [isLoginEmailPassword, setIsLoginEmailPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const AuthGoogle = async () => {
    try {
      await handleAuth(signWithGoogle);
    } catch (e) { }
  };
  const AuthApple = async () => {
    try {
      await handleAuth(signWithApple);
    } catch (e) { }

  };

  const navigateTransferDevice = () => {
    navigate("/transfer-device", {state: {purchase: history.state?.usr?.purchase}})
  }

  const handleAuth = async (signIn: ISignIn) => {
    try {
      if (process.env.REACT_APP_ENV === "AUTOMATION") {
        setIsLoginEmailPassword(true);
      } else {
        const response = await signIn.execute();
        onLoginSuccess(response);
      }
    } catch (e) {
    }
  };

  const onLoginSuccess = (user: UserInterface) => {
    setAuthMethod(user.authMethod);
    navigate("/purchase", {state: {purchase: history.state?.usr?.purchase}});
  }

  const handleEmailLogin = async () => {
    try {
      setErrorMessage("");

      const response = await signWithEmailAndPassword.execute({email, password});
      setIsLoginEmailPassword(false);
      onLoginSuccess(response);

    } catch (e) {
      if (e instanceof Error) {
        setErrorMessage(e.message);
      } else {
        setErrorMessage(String(e));
      }
    }
  };


  return {
    showBackButton,
    isLoginEmailPassword,
    setIsLoginEmailPassword,
    email,
    setEmail,
    password,
    setPassword,
    errorMessage,
    setErrorMessage,
    AuthGoogle,
    AuthApple,
    navigateTransferDevice,
    handleEmailLogin
  }
}