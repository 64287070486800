import { GetProductsUsecaseImpl } from "usecases/get_products_usecase";
import ProductList from "../product_list";
import { PhoneEntity } from "features/brand_and_model_search";
import { ProductModel } from "models";

interface StepChooseProductProps {
    phone?: PhoneEntity;
    product?: ProductModel;
    onNext: (product: ProductModel) => void;
    setProduct: (product: ProductModel) => void;
}

function StepChooseProduct({ phone, product, onNext, setProduct }: StepChooseProductProps) {
    return (
        <ProductList
            phone={phone}
            getProducts={new GetProductsUsecaseImpl()}
            product={product}
            setProduct={setProduct}
            onTap={(product) => {
                onNext(product);
            }}
            data-testid="ProductList"
        />
    );
}

export default StepChooseProduct;