import { TicketEntity } from "features/purchase/domain/entities/ticket_entity"
import { useProfileCertificateController } from "features/purchase/presenter/components/profile_certificates_controller"
import { CardWrapper, ExaLoading, IconChevronRight } from "gosafe"
import { Key } from "react"
import { transformDate } from "utils/transformDate"

export default function ProfileCertificates() {

  const { isLoading, certificates } = useProfileCertificateController()

  return (
    <div className="p-2">
      <p>Visualize aqui seus certificados. Eles validam a atividade do seu serviço contratado.</p>
      {isLoading ?
        <div className="flex items-center justify-center p-12">
          <ExaLoading />
        </div>
        : (certificates !== null && certificates !== undefined) && (
          <ul className="flex flex-col gap-3 mt-5">
            {certificates.map((cert: TicketEntity, index: Key) => (
              <CardWrapper className={(cert.state !== 'active') ? 'bg-neutral-500' : ''} key={index}>
                <a
                  href={cert.state === 'active' ? cert.policy_url : undefined}
                  target="_blank"

                  className="group flex w-full cursor-pointer items-center justify-between rounded-lg px-5 py-4"
                >
                  <div className="flex flex-col gap-1">
                    <p className={`text-sm font-bold ${(cert.state !== 'active') ? 'text-neutral-200' : ''}`}>Certificado {cert.policy_number}</p>
                    <p className={`text-xs font-normal ${(cert.state !== 'active') ? 'text-neutral-200' : ''}`}>
                      {cert.state === 'active' ? 'Ativo' : 'Expirado'} em {cert.state === 'active' ? transformDate(cert.created_at) : transformDate(cert.updated_at)}
                    </p>
                  </div>
                  {cert.state === 'active' && <IconChevronRight />}
                </a>
              </CardWrapper>
            ))}
          </ul>
        )}
    </div>
  )
}
