import { createContext, useContext } from "react";
import { IUpdateLeadUsecase } from "./domain/usecases/update_lead_usecase";

const UpdateLeadContext = createContext<IUpdateLeadUsecase |undefined>(undefined);

export function useUpdateLeadUsecase() {
  const context = useContext(UpdateLeadContext)
  if (context === undefined) {
    throw new Error('IUpdateLeadUsecase not found');
  }

  return context;
}

export default UpdateLeadContext;
