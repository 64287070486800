
import OwnershipValidationBinding from './ownership_validation_bindings';
import OwnershipValidationPage from './presenter/ownership_validation_page';
import { IHttpClient } from 'core/protocols/http_client';
import { CustomerEntity } from "features/purchase/domain/entities/customer_entity";

interface OwnershipValidationProps {
    httpClient: IHttpClient;
    onNextStep: () => void;
    phone?: string;
    onPreviousStep: () => void;
  }

export default function OwnershipValidation({httpClient, phone, onNextStep, onPreviousStep}: OwnershipValidationProps){
    return (
        <OwnershipValidationBinding  httpClient={httpClient}>
            <OwnershipValidationPage  onNextStep={onNextStep}
             phone={phone}
            onPreviousStep={onPreviousStep} />
        </OwnershipValidationBinding>
    );

}