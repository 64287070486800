import { Button, Divider, Text } from 'gosafe'



const CertificateActive = () => {

    return (
        <div className='py-20 flex justify-center items-center'>
            <div className="flex flex-col md:flex-row items-center justify-center">
                <div className="flex-1 bg-blue-500 p-4 ">
                <div className="content-center p-0 columns-1">
                        <header className='pb-5' >
                            <Text fontSize='text-3xl' fontWeight='font-medium'>Seu seguro está ativo!</Text>
                            <Divider />
                        </header>

                        <Text fontSize='text-lg' color='text-neutral-10'>A apólice foi enviada para o seu email :)</Text>

                    </div>
                </div>
                <div className="flex-1 pt-10">
                    <div className="flex flex-row items-center justify-center">
                        <img className="" width={300} alt="" src="/images/woman-talking-about-security.png" />
                    </div>
                </div>

                
            </div>

            <div className='flex items-center justify-center mt-4 md:mt-0 pt-20'>
            </div>
        </div>
    )
}

export default CertificateActive;