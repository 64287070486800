import React, { ChangeEvent, KeyboardEvent, MouseEventHandler, ReactNode, useState } from 'react';

interface TextFieldCodeProps {
    id: string,
    size?: 'medium' | 'large',
    text?: string,
    showLabel?: boolean,
    textLabel?: string,
    showHelper?: boolean,
    textHelper?: string,
    hintText?: string,
    showIcon?: boolean,
    icon?: ReactNode,
    iconError?: ReactNode,
    onClick?: MouseEventHandler,
    onCodeChange?: (code: string[]) => void;
}

const VerificationCodeInput: React.FC<TextFieldCodeProps> = ({
    id,
    showLabel = true,
    textLabel = 'Label',
    hintText = 'Label',
    showHelper = false,
    textHelper = 'O código está incorreto',
    onCodeChange,
}) => {
    const [code, setCode] = useState<string[]>(new Array(6).fill(''));

    const handleChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
        const value = e.target.value;
        if (/^[0-9]$/.test(value) || value === '') {
            const newCode = [...code];
            newCode[index] = value;
            setCode(newCode);
            if (value !== '' && index < 5) {
                const nextInput = document.getElementById(`digit-${index + 1}`);
                if (nextInput) {
                    nextInput.focus();
                }
            }
            if (onCodeChange) {
                onCodeChange(newCode);
            }
        }
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
        if (e.keyCode === 8 && e.currentTarget.value === '') {
            const prevInput = document.getElementById(`digit-${index - 1}`);
            if (prevInput) {
                const newCode = [...code];
                newCode[index - 1] = '';
                setCode(newCode);
                prevInput.focus();
            }
        }
    };

    return (
        <div className="flex flex-col space-x-2">
            {showLabel && (
                <span data-testid={`VerificationCode-${id}-text-label`} className="font-serif font-bold text-xs text-neutral-30">
                    {textLabel}
                </span>
            )}
            <div className="flex mt-2">
                <div className="flex">
                    {code.map((digit, index) => (
                        <div key={index} className="flex flex-col items-center justify-center" style={{ margin: '0 5px' }}>
                            <input
                                key={index}
                                id={`digit-${index}`}
                                type="text"
                                placeholder={hintText}
                                maxLength={1}
                                value={digit}
                                onChange={(e) => handleChange(e, index)}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                className={`placeholder:text-sm w-10 h-10 text-center text-4xl rounded focus:outline-none focus:ring-2 focus:ring-blue-500`}
                                data-testid={`VerificationCode-${id}-input-${index}`}
                            />
                            {showHelper ?
                                <div style={{ width: '90%', height: 2, marginLeft: 5, marginTop: 5, marginRight: 5, backgroundColor: 'red', borderRadius: 10 }}></div>
                                : <div style={{ width: '90%', height: 2, marginLeft: 5, marginTop: 5, marginRight: 5, backgroundColor: '#b3b3b3', borderRadius: 10 }}></div>}
                        </div>
                    ))}
                </div>
            </div>
            {showHelper && <span data-testid={`VerificationCode-${id}-text-helper`} className=" text-xs text-error pt-2">{textHelper}</span>}
        </div>
    );
};

export default VerificationCodeInput;