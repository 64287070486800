import { Link } from "react-router-dom";

import { IconCross, Text } from "gosafe";

const FooterSection = () => {
    const chatBot = (e: any) => {
        e.preventDefault();
        (document.getElementById("btn-chatbot") as HTMLElement).click();
    };

  const currentDate = () => {
    const date = new Date();
    return date.getFullYear();
  };

  return (
    <>
      <footer className="footer-home">
        <div className="content-center">
          <div className="flex flex-col items-start justify-between md:flex-row md:items-end">
            <div className="basis-1/2">
              <Text fontWeight="font-medium" fontSize="text-3xl">
                Ficou com <br /> alguma dúvida?
              </Text>
              <div className="h-8" />
              <p className="text-xs text-primary">
                Acesse nosso chat:{" "}
                <a href="#" onClick={chatBot}>
                  /Ajuda
                </a>
              </p>
              <p className="text-xs text-primary">
                Email: suporte@testefone.com.br
              </p>
              <div className="h-8" />
            </div>
            <div className="basis-1/2">
              <ul className="flex flex-col gap-[16px]">
                <li className="flex items-center gap-4">
                  <IconCross />
                  <Link
                    className="text-base font-medium text-primary"
                    to="/faq"
                  >
                    Como funcionam os testes?
                  </Link>
                </li>
                <li className="flex items-center gap-4">
                  <IconCross />
                  <Link
                    className="text-base font-medium text-primary"
                    to="/faq"
                  >
                    O que é o Testefone?
                  </Link>
                </li>
                <li className="flex items-center gap-4">
                  <IconCross />
                  <Link
                    className="text-base font-medium text-primary"
                    to="/faq"
                  >
                    Como posso contratar o seguro?
                  </Link>
                </li>
                <li className="flex items-center gap-4">
                  <IconCross />
                  <Link
                    className="text-base font-medium text-primary"
                    to="/faq"
                  >
                    Acessar todas as perguntas frequentes
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>

      <section className="copy">
        <div className="content-center">
          <ul className="list-copy">
            <li>
              <p>
                ©{currentDate()} Testefone by EXA - Todos os direitos
                reservados
              </p>
            </li>

            <li>
              <p>
                <Link to="/faq">Ajuda</Link>
              </p>
            </li>

            <li>
              <p>
                <Link to="/faq">FAQ</Link>
              </p>
            </li>

            <li>
              <p>
                <a
                  href="https://dpo.privacytools.com.br/policy-view/G0EB4Xp1Q/1/-/pt_BR?s=1691772656866"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Termos de Uso e Politica de Privacidade
                </a>
              </p>
            </li>

            <li>
              <p>
                <a
                  href="https://portal.privacytools.com.br/portal/ee72de9b-7eff-4a53-8ac5-2b411def8b4b"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Portal de Privacidade
                </a>
              </p>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default FooterSection;
