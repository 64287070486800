import { useProfilePhoneInfoController } from "features/purchase/presenter/components/profile_phone_info_controller";
import { CardWrapper, IconChevronRight } from "gosafe/atoms";

interface OptionsProfileProps {
  onTap: (step: number) => void;
}

interface IOptionsProfile {
  title: string;
  subtitle: string;
  disabled: boolean;
  step: number;
}



export default function ProfileOptions({ onTap }: OptionsProfileProps) {
  const {orderData} = useProfilePhoneInfoController()

  const optionProfile: IOptionsProfile[] = [
    {
      title: "Informações pessoais",
      subtitle: "Nome, CPF e endereço",
      disabled: false,
      step: 1,
    },
    {
      title: "Dados do celular",
      subtitle: "Número e IMEI",
      disabled: !orderData,
      step: 2,
    },
  ];

  return (
    <div className="flex w-full flex-col gap-3 py-5">
      {optionProfile.map(({ title, subtitle, step, disabled }: IOptionsProfile) => (
        <CardWrapper key={step}>
          <div
            onClick={disabled ? () => {} : () => onTap(step)}
            className="group flex w-full cursor-pointer items-center justify-between rounded-lg px-5 py-4"
          >
            <div className="flex flex-col gap-1">
              <p className={`text-sm font-bold ${disabled ? 'text-neutral-60': ''}`}>{title}</p>
              <p className={`text-sm font-normal ${disabled ? 'text-neutral-60': ''}`}>
                {subtitle}
              </p>
            </div>
            {!disabled && <IconChevronRight />}
          </div>
        </CardWrapper>
      ))}
    </div>
  );
}
