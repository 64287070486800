import style from './style.module.css'
import LOGO from './images/logos-footer.png'

const Footer = () => {
    return (
        <>
            <footer className={style.footer}>
                <div>
                    <p><small>© 2023 Testefone by EXA - Todos os direitos reservados</small></p>
                </div>
                
                <div>
                    <a href="https://dpo.privacytools.com.br/policy-view/G0EB4Xp1Q/1/-/pt_BR?s=1691772656866" target='_blank'>Termos de Uso e Política de Privacidade</a>
                </div>

                <div>
                    <p>Oferecido por:</p>
                    <img src={LOGO} alt="Logos de patrocinadores" />
                </div>
            </footer>
        </>
    )
}

export default Footer