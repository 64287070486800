export class Subscription {
    subscribed_at?: string;
    subscribed_origin?: string;

    constructor(args: {
        subscribed_at?: string;
        subscribed_origin?: string;
    }) {
        this.subscribed_at = args.subscribed_at;
        this.subscribed_origin = args.subscribed_origin;
    }
}

export class TicketEntity {
    auth_uid?: string;
    brand_name?: string;
    created_at?: string;
    end_validity?: string;
    id?: number;
    imei?: string;
    inspection_url?: string;
    manual_url?: string;
    model_name?: string;
    msisdn?: string;
    policy_number?: string;
    policy_url?: string;
    product_name?: string;
    score?: number;
    start_validity?: string;
    state?: string;
    subscription?: Subscription;
    suspended_at?: string;
    updated_at?: string;

    constructor(args: {
        auth_uid?: string;
        brand_name?: string;
        created_at?: string;
        end_validity?: string;
        id?: number;
        imei?: string;
        inspection_url?: string;
        manual_url?: string;
        model_name?: string;
        msisdn?: string;
        policy_number?: string;
        policy_url?: string;
        product_name?: string;
        score?: number;
        start_validity?: string;
        state?: string;
        subscription?: Subscription;
        suspended_at?: string;
        updated_at?: string;
    }) {
        this.auth_uid = args.auth_uid;
        this.brand_name = args.brand_name;
        this.created_at = args.created_at;
        this.end_validity = args.end_validity;
        this.id = args.id;
        this.imei = args.imei;
        this.inspection_url = args.inspection_url;
        this.manual_url = args.manual_url;
        this.model_name = args.model_name;
        this.msisdn = args.msisdn;
        this.policy_number = args.policy_number;
        this.policy_url = args.policy_url;
        this.product_name = args.product_name;
        this.score = args.score;
        this.start_validity = args.start_validity;
        this.state = args.state;
        this.subscription = args.subscription;
        this.suspended_at = args.suspended_at;
        this.updated_at = args.updated_at;
    }
}