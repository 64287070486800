export default class PlanModel {
  amount: number;
  category: string;
  code: string;
  id: number;
  manualUrl: string;
  maxIndemnification: number;
  name: string;

  constructor(
    amount: number,
    category: string,
    code: string,
    id: number,
    manualUrl: string,
    maxIndemnification: number,
    name: string
  ) {
    this.amount = amount;
    this.category = category;
    this.code = code;
    this.id = id;
    this.manualUrl = manualUrl;
    this.maxIndemnification = maxIndemnification;
    this.name = name;
  }
}
