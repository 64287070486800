class PaymentOutputEntity {
    authUid?: string; 
    createdAt?: string;
    endValidity?: string;
    id?: number;
    inspectionUrl?: string;
    manualUrl?: string;
    modelName?: string;
    msisdn?: string;
    policyNumber?: string;
    policyUrl?: string;
    productName?: string;
    score?: number;
    startValidity?: string;
    state?: string;
    updatedAt?: string;

    constructor(args: {
        authUid?: string,
        createdAt?: string,
        endValidity?: string,
        id?: number,
        inspectionUrl?: string,
        manualUrl?: string,
        modelName?: string,
        msisdn?: string,
        policyNumber?: string,
        policyUrl?: string,
        productName?: string,
        score?: number,
        startValidity?: string,
        state?: string,
        updatedAt?: string
    }) {
        this.authUid = args.authUid;
        this.createdAt = args.createdAt;
        this.endValidity = args.endValidity;
        this.id = args.id;
        this.inspectionUrl = args.inspectionUrl;
        this.manualUrl = args.manualUrl;
        this.modelName = args.modelName;
        this.msisdn = args.msisdn;
        this.policyNumber = args.policyNumber;
        this.policyUrl = args.policyUrl;
        this.productName = args.productName;
        this.score = args.score;
        this.startValidity = args.startValidity;
        this.state = args.state;
        this.updatedAt = args.updatedAt;
    }

    static fromJson(json: any) {
        return new PaymentOutputEntity({
            authUid: json.auth_uid,
            createdAt: json.created_at,
            endValidity: json.end_validity,
            id: json.id,
            inspectionUrl: json.inspection_url,
            manualUrl: json.manual_url,
            modelName: json.model_name,
            msisdn: json.msisdn,
            policyNumber: json.policy_number,
            policyUrl: json.policy_url,
            productName: json.product_name,
            score: json.score,
            startValidity: json.start_validity,
            state: json.state,
            updatedAt: json.updated_at
        });
    }
 }

export default PaymentOutputEntity;