import { BackButton } from "gosafe/molecules/buttons";

export default function TestefoneHeaderThin() {
  return (
    <div className="flex items-center justify-between bg-primary px-4 py-4">
      <div className="inline md:hidden">
        <BackButton
          color="text-neutral-100"
          onClick={() => {
            window.history.back();
          }}
        />
      </div>
      <div>
        <img
          src="/images/logo-testefone.png"
          alt="Logo testefone"
          width={160}
        />
      </div>
    </div>
  );
}
