import { Form, FormikProvider } from "formik";
import {
  IconArrowRight,
  InfinityLoading,
  SecondaryButton,
  TextField,
  TextLink,
} from "gosafe";
import CheckboxField from "gosafe/molecules/checkbox_field";

import { useProfileFormController } from "../presenter/profile_form_controller";
import ProfileEntity from "../domain/entities/profile_entity";
import ProfileOutputType from "../domain/types/profile_output_type";

interface ProfileFormProps {
  onNextStep?: (profile: ProfileOutputType) => void;
  onFailure?: (error: any) => void;
  profile?: ProfileEntity
}

export default function ProfileForm({ onNextStep, onFailure, profile }: ProfileFormProps) {
  const {
    form,
    isDisabled,
    addressLoading,
    withoutComplement,
    withoutNumber,
    showFormAddress,
    handleZipcode,
    checkWithoutComplement,
    checkWithoutNumber,
    requesting
  } = useProfileFormController({ onNextStep, onFailure, initialValue: profile });

  return (
    <FormikProvider value={form}>
      <Form>
        <div data-testeid="ProfileForm">
          <form className="grid grid-cols-2 gap-3">
            <div className="">
              <TextField
                name="cpf"
                textLabel="CPF"
                hintText="000.000.000-00"
                mask={"999.999.999-99"}
                value={form.values.cpf}
                onChange={form.handleChange}
                showErros={form.touched.cpf}
                errorMessage={form.errors.cpf}
              />
            </div>
            <div className="">
              <TextField
                name="phone"
                textLabel="Celular"
                hintText="00 00000-0000"
                mask={"99 99999-9999"}
                value={form.values.phone}
                onChange={form.handleChange}
                showErros={form.touched.phone}
                errorMessage={form.errors.phone}
              />
            </div>
            <div className="">
              <TextField
                name="zip_code"
                textLabel="CEP"
                hintText="00000-000"
                mask={"99999-999"}
                showHelper={true}
                textHelper={
                  <TextLink
                    url="https://buscacepinter.correios.com.br/app/endereco/index.php"
                    label="Não sei meu CEP"
                  />
                }
                value={form.values.zip_code}
                onChange={form.handleChange}
                showErros={form.touched.zip_code}
                errorMessage={form.errors.zip_code}
              />
            </div>
            {showFormAddress && (
              <>
                <div className="">
                  <TextField
                    name="address"
                    textLabel="Rua"
                    hintText="Digite o nome da rua"
                    value={form.values.address}
                    onChange={form.handleChange}
                    disabled={isDisabled}
                    showErros={form.touched.address}
                    errorMessage={form.errors.address}
                  />
                </div>
                <div className="space-y-2">
                  <TextField
                    name="number"
                    textLabel="Número"
                    hintText="Digite o número"
                    value={form.values.number}
                    onChange={form.handleChange}
                    disabled={isDisabled || withoutNumber}
                    showErros={form.touched.number}
                    errorMessage={form.errors.number}
                  />
                  <CheckboxField
                    id="withoutNumber"
                    textLabel="Sem número"
                    showLabel
                    value={withoutNumber}
                    disabled={isDisabled}
                    onChange={value => checkWithoutNumber(value)}
                  />
                </div>
                <div className="space-y-2">
                  <TextField
                    name="complement"
                    textLabel="Complemento"
                    hintText="Digite o complemento"
                    value={form.values.complement}
                    onChange={form.handleChange}
                    disabled={isDisabled || withoutComplement}
                    showErros={form.touched.complement}
                    errorMessage={form.errors.complement}
                  />
                  <CheckboxField
                    id="withoutComplement"
                    textLabel="Sem complemento"
                    showLabel
                    value={withoutComplement}
                    disabled={isDisabled}
                    onChange={value => checkWithoutComplement(value)}
                  />
                </div>
                <div>
                  <TextField
                    name="neighborhood"
                    textLabel="Bairro"
                    hintText="Digite o bairro"
                    value={form.values.neighborhood}
                    onChange={form.handleChange}
                    disabled={isDisabled}
                    showErros={form.touched.neighborhood}
                    errorMessage={form.errors.neighborhood}
                  />
                </div>
                <div>
                  <TextField
                    name="city"
                    textLabel="Cidade"
                    hintText="Digite o número"
                    value={form.values.city}
                    onChange={form.handleChange}
                    disabled={isDisabled}
                    showErros={form.touched.city}
                    errorMessage={form.errors.city}
                  />
                </div>
                <div className="col-span-2 py-3">
                  <div className="grid grid-cols-2 gap-3">
                    <div />
                    <SecondaryButton
                      id='btn-submit'
                      text={requesting ? "" : "Continuar"}
                      showIconRight={true}
                      iconRight={requesting ? <InfinityLoading
                        width={24}
                        strokeColor="text-primary"
                      /> : <IconArrowRight />}
                      onClick={form.submitForm}
                    />
                  </div>
                </div>
              </>
            )}
            {!showFormAddress && (
              <div className="flex items-center justify-center py-3">
                <div className="flex h-12 w-full ">
                  <SecondaryButton
                    id='btn-submit'
                    aligment="justify-center"
                    text={!addressLoading ? "Continuar" : ""}
                    enabled={!isDisabled}
                    infinity={true}
                    showIconRight={true}
                    iconRight={
                      addressLoading ? (
                        <InfinityLoading
                          width={24}
                          strokeColor="text-primary"
                        />
                      ) : (
                        <IconArrowRight />
                      )
                    }
                    onClick={() => {
                      handleZipcode();
                    }}
                  />
                </div>
              </div>
            )}
          </form>
        </div>
      </Form>
    </FormikProvider>
  );
}
