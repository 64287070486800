import { BackButton } from "gosafe/molecules/buttons";
import { useState, useEffect } from "react";
import { CardWrapper } from "gosafe";
import { Divider } from "gosafe/atoms";
import MyOptionsTitle from "./components/title_options";
import MyOptionsSubtitle from "./components/subtitle_options";
import ButtonResendOption from "./components/button_resend_option";
import SMSTextfieldCode from "gosafe/atoms/text_field/text_field_code";
import SecondaryButton from "gosafe/molecules/buttons/secondary_button";
import { useSendCodeUsecase } from "../send_code_context";
import { useValidateCodeUsecase } from "../validate_code_context";

interface OwnershipValidationPageProps {
    phone?: string;
    onNextStep: () => void;
    onPreviousStep: () => void;
}

function OwnershipValidationPage({ onNextStep, phone, onPreviousStep }: OwnershipValidationPageProps) {
    const [count, setCount] = useState(90);
    const [error, setMessageError] = useState(false);
    const [code, setCode] = useState('');
    const [phoneNumber, setPhoneNumber] = useState(phone ?? "");
    const sendCode = useSendCodeUsecase();
    const validateCode = useValidateCodeUsecase()

    useEffect(() => {
        (async () => {
            await handleResendClick();
            countdown();
        })();

    }, []);

    const countdown = () => {
        const timer = setInterval(() => {
            setCount((prevCount) => {
                if (prevCount <= 1) {
                    clearInterval(timer);
                    return 0;
                }
                return prevCount - 1;
            });
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    };

    const handleResendClick = async () => {
        await sendCode.execute(phoneNumber)
            .then(() => { })
            .catch(() => { });
    };

    const handleButtonClick = async () => {
        if (!phoneNumber || !code) {
            setMessageError(true);
            return;
        }
        await validateCode.execute(phoneNumber, code)
            .then((result) => {
                if (result){
                    onNextStep();
                }
                setMessageError(!result);
            })
            .catch(() => {
                setMessageError(true);
            });
    };

    return (
        <div className="content-center pb-8">
            <div className="hidden md:inline">
                <div className="py-10">
                    <BackButton onClick={() => {
                        onPreviousStep();
                    }}></BackButton>
                </div>
            </div>
            <div className="flex flex-col gap-0 md:gap-40 md:flex-row max-w-full md:max-w-[1100px]">
                <div className="basis-1/2 order-1">
                    <div className="flex flex-col">
                        <Divider y={16} />
                        <MyOptionsTitle />
                        <Divider y={16} />
                        <MyOptionsSubtitle phoneNumber={phoneNumber} />
                        {error && <>
                            <Divider y={30} />
                            <ButtonResendOption id="btn-reenviar-codigo" onClick={handleResendClick} text="Reenviar código" icon={<img src="/images/icon-phone.svg" />} />
                        </>}
                        <Divider y={15} />
                    </div>
                </div>

                <div className="basis-1/2 order-2">
                    <div className="flex flex-col md:flex-row">
                        <div className="flex justify-end py-4">
                            <CardWrapper >
                                <div className="grid grid-cols-1 gap-3 py-8 px-5">
                                    {!error && <>
                                        <ButtonResendOption id="btn-reenviar-codigo" onClick={handleResendClick} text="Reenviar código" icon={<img src="/images/icon-phone.svg" />} />
                                        <Divider y={2} />
                                    </>}
                                    <div><SMSTextfieldCode id='sms' textLabel="Código de verificação"
                                        onCodeChange={(code) => {
                                            setCode(code.join(''));
                                        }} hintText="0" showHelper={error} ></SMSTextfieldCode></div>
                                    {!error && <span className="text-neutral-30 text-xs ">Reenviar código em {count} segundos</span>}
                                    <div><SecondaryButton id="btn-submit" infinity={true} onClick={handleButtonClick} text="Ir para pagamento" /></div>
                                    {error && <div data-testid={`text-helper-wrong-code`} className="text-neutral-30 text-xs">Caso o código esteja correto, <button id="btn-try-again" className="text-blue-dark" onClick={handleResendClick}><u>clique aqui</u></button> para enviar novamente</div>}
                                </div>
                            </CardWrapper>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OwnershipValidationPage;