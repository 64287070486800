import { MouseEventHandler, ReactNode } from "react"

interface FloatingButtonProps {
    id?: string
    children?: ReactNode
    onClick?: MouseEventHandler<HTMLButtonElement>
    color?: string
}

function FloatingButton({ id, children, onClick, color = 'bg-secondary' }: FloatingButtonProps) {
    return (
        <button id={id} onClick={onClick} className={`fixed bottom-8 right-4 rounded-full p-1 ${color}`}>
            <div className='w-66 p-2'>
                {children}
            </div>
        </button>
    )
}

export default FloatingButton;
