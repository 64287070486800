import CreditCardEntity from "./credit_card_entity";

export default class PaymentEntity {

    private method: string;
    private creditCard: CreditCardEntity;

    constructor(args: {
        method: string,
        creditCard: CreditCardEntity
    }) {
        this.method = args.method;
        this.creditCard = args.creditCard;
    }

    toMap() {
        return {
            method: this.method,
            credit_card: this.creditCard.toMap()
        }
    }
}