import { IHttpClient } from "core/protocols/http_client"
import * as Sentry from "@sentry/react"


export interface ISearchByCpfUsecase {
    execute(cpf: string): Promise<SearchByCpfOutput>
}

type SearchByCpfOutput = {
    BirthDate: string,
    CPF: string,
    Name: string
}

class SearchByCpfUsecaseImpl implements ISearchByCpfUsecase {
    private httpClient: IHttpClient

    constructor(httpClient: IHttpClient) {
        this.httpClient = httpClient
    }

    async execute(cpf: string): Promise<SearchByCpfOutput> {
        try {
            const response = await this.httpClient.get<SearchByCpfOutput>(`/bff/v1/get_cpf_bigquery/${cpf}`)
            return response.data
        } catch (error) {
            Sentry.captureException(error, {
                tags: {
                    'feature.authentication': "SignInWithEmailAndPasswordUsecaseImpl",
                    'api.endpoint': `/bff/v1/get_cpf_bigquery/${cpf}`
                }});
            
            throw new Error('Internal Server Error')
        }
    }
}

export default SearchByCpfUsecaseImpl