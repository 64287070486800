import { useHttpClient } from "bindings/http_binding";
import GetUserByIdUsecaseImpl from "./domain/usecases/get_user_by_id_usecase";
import { GetUserByIdContext } from "./get_user_by_id_context";
import GetUserByTicketUsecaseImpl from "./domain/usecases/get_ticket_by_user_usecase";
import { GetTicketByIdContext } from "./get_ticket_by_user_context";
import PurchasePage from "./presenter/purchase_page";
import PurchaseBinding from "./presenter/purchase_binding";
import { DrawerProvider } from "gosafe";

function Purchase() {
  const httpClient = useHttpClient();

  return (
    <GetUserByIdContext.Provider value={new GetUserByIdUsecaseImpl(httpClient)}>
      <GetTicketByIdContext.Provider value={new GetUserByTicketUsecaseImpl(httpClient)}>
        <PurchaseBinding httpClient={httpClient}>
          <DrawerProvider>
            <PurchasePage />
          </DrawerProvider>
        </PurchaseBinding>
      </GetTicketByIdContext.Provider>
    </GetUserByIdContext.Provider>
  );
}

export default Purchase;
