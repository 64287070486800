import { Text } from "gosafe";
import UrlApps from "pages/landing_page/url_apps";
import "./style.css";

interface BannerSectionProps {
  urlApps: UrlApps;
}


const BannerSection = ({urlApps}: BannerSectionProps) => {
  return (
    <section
      className="solution"
      style={{ backgroundImage: `url(/images/vetor-content.png)` }}
    >
      <div className="content-center">
        <div className="str">
          <div className="img">
            <img
              src="/images/n-figura-mulher-celular.png"
              alt="Vetor Mulher com celular"
              width={522}
              height={500}
            />
          </div>
          <div className="flex basis-1/2 flex-col gap-10 p-14 md:justify-center">
            <div>
              <Text
                color="text-secondary"
                fontWeight="font-normal"
                fontSize="text-3xl"
              >
                E ainda tenha um relatório completo do seu celular
              </Text>
              <div className="h-8" />
              <Text
                fontWeight="font-thin"
                color="text-neutral-100"
                fontSize="text-base"
              >
                No app, você consegue fazer mais de 20 testes para medir a performance do seu aparelho. Descubra mais e faça os testes gratuitamente.
              </Text>
              <div className="h-8" />

              <div className="btn-apps desk">
                  <p>Faça o download agora:</p>

                  <ul>
                    <li>
                      <a
                        href={urlApps.appleStore}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={"/images/button-apple-store.png"}
                          alt="AppleStore"
                          width={220}
                          height={80}
                        />
                      </a>
                    </li>
                    
                    <li>
                      <a
                        href={urlApps.playStore}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={"/images/button-play-store.png"}
                          alt="PlayStore"
                          width={220}
                          height={80}
                        />
                      </a>
                    </li>

                  </ul>
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerSection;
