import { ReactNode } from "react";
import WhyHire from "./why_hire";
import { BackButton, Divider, DotStepper } from "gosafe";
import MyOptions, { MyOptionsTitle } from "./my_options";
import { ProductModel } from "models";
import { PhoneEntity } from "features/brand_and_model_search";
import PurchaseStepType from "../purchase_step_type";

interface PurchaseTemplateProps {
    phone?: PhoneEntity;
    product?: ProductModel;
    onRemovePhone?: () => void;
    onRemoveProduct?: () => void; children: ReactNode;
    currentStep: PurchaseStepType;
    onBack?: () => void;
}

function PurchaseTemplate({
    phone,
    product,
    onRemovePhone,
    onRemoveProduct,
    currentStep,
    onBack,
    children }: PurchaseTemplateProps) {

    const showSteps = currentStep === 'CHOOSE_PHONE' || currentStep === 'CHOOSE_PRODUCT' || currentStep === 'SET_PROFILE';

    return (
        <div className="content-center pb-8">
            <div className="hidden md:inline">
                <div className="py-10">
                    <BackButton
                        onClick={onBack}
                    />
                </div>
            </div>
            <div className="inline md:hidden">
                <div className="flex py-10">
                    {showSteps && <DotStepper
                        steps={3}
                        align="start"
                        currentStep={currentStep}
                    />}
                </div>
                <MyOptionsTitle currentStep={currentStep}/>
            </div>

            <div className="flex max-w-[1100px] flex-col gap-0 md:flex-row md:gap-40">
                <div className="order-last basis-1/2 md:order-1">
                    <MyOptions
                        currentStep={currentStep}
                        phone={phone}
                        product={product}
                        onPhoneTap={onRemovePhone}
                        onProductTap={onRemoveProduct}
                    />
                </div>

                <div className="order-2 basis-1/2">
                    <div className="hidden md:inline">
                        <div className="flex justify-end py-4">
                            {showSteps && <DotStepper steps={3} currentStep={currentStep} />}
                        </div>
                    </div>
                    <div className="flex flex-col gap-4">
                        <Divider />
                        {children}
                    </div>
                </div>
            </div>
            <WhyHire />
        </div>
    )
}

export default PurchaseTemplate;