import { IHttpClient } from "core/protocols/http_client";
import PaymentInputEntity from "../entities/payment_input_entity";
import PaymentOutputEntity from "../entities/payment_output_entity";
import { IAuthState } from "core/authentication/auth_state";
import * as Sentry from '@sentry/react';

export interface ISendPaymentUseCase {
    execute(input: PaymentInputEntity): Promise<PaymentOutputEntity>;
}

class SendPaymentUseCaseImpl implements ISendPaymentUseCase {
    private httpClient: IHttpClient;
    private authState: IAuthState;

    constructor(httpClient: IHttpClient, authState: IAuthState) {
        this.httpClient = httpClient;
        this.authState = authState;
    }

    async execute(input: PaymentInputEntity): Promise<PaymentOutputEntity> {

        return this.httpClient.post('/bff/v1/web/orders', input.toMap(), {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': this.authState.accessToken ?? '',
        }, true,
        ).then((response) => {
            return PaymentOutputEntity.fromJson(response);
        }).catch((err) => {
            Sentry.captureException(err, {
                tags: {
                    'feature.checkout': true,
                    'context.payment': true,
                    'api.endpoint': '/bff/v1/web/orders',
                },
                extra: {
                    input: input,
                    info: 'Error in SendPaymentUseCaseImpl execute method',
                }
            });
            throw err
        });
    }
}

export default SendPaymentUseCaseImpl;