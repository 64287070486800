import PlanModel from "./plan_model";

export default class ProductModel {
  price: number;
  description: string;
  plan: PlanModel;
  planCode: string;
  planDescription: string;
  planName: string;
  smartphoneModelName: string;
  brandId: number;
  id: number;
  modelId: number;

  constructor(
    amount: number,
    brandId: number,
    id: number,
    modelId: number,
    plan: PlanModel,
    planCode: string,
    planDescription: string,
    planName: string,
    smartphoneModelName: string
  ) {
    this.price = amount;
    this.description = planDescription;
    this.plan = plan;
    this.planCode = planCode;
    this.planDescription = planDescription;
    this.planName = planName;
    this.smartphoneModelName = smartphoneModelName;
    this.brandId = brandId;
    this.id = id;
    this.modelId = modelId;
  }
}
