import { IAuthState } from "core/authentication/auth_state";
import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from 'yup';
import CreditCardEntity from "../domain/entities/credit_card_entity";
import PaymentEntity from "../domain/entities/payment_entity";
import PaymentInputEntity from "../domain/entities/payment_input_entity";
import { ISendPaymentUseCase } from "../domain/usecases/send_payment_usecase";
import { CheckoutViewProps } from "./checkout_view";

export type FlagType = 'visa' | 'mastercard' | 'amex' | 'diners' | 'discover' | 'elo' | 'jcb' | undefined;

class CardRegex {
    name: FlagType
    regex: RegExp

    constructor(name: FlagType, regex: RegExp) {
        this.name = name;
        this.regex = regex;
    }
}

function useCheckoutController(
    sendPayment: ISendPaymentUseCase,
    authStore: IAuthState,
    props: CheckoutViewProps
) {


    const [flag, setFlag] = useState<FlagType>();
    const [loading, setLoading] = useState(false);

    const form = useFormik({
        validateOnBlur: true,
        validateOnChange: true,
        initialValues: {
            number: '',
            name: '',
            expiry: '',
            cvv: ''
        },
        validationSchema: Yup.object().shape({
            number: Yup.string().required('Campo obrigatório').test('number', 'Cartão inválido', (value) => {
                return regexCC(value ?? '') !== undefined;
            }),
            name: Yup.string().required('Campo obrigatório'),
            expiry: Yup.string().required('Campo obrigatório').matches(/^(0[1-9]|1[0-2])\/\d{2}$/, 'Data incorreta'),
            cvv: Yup.string().required('Campo obrigatório').matches(/^\d{3}$/, 'CVV incorreto')
        }),
        onSubmit: (values) => {
            form.setTouched({
                number: true,
                name: true,
                expiry: true,
                cvv: true
            });

            if (form.isValid) {

                setLoading(true);

                const paymentInputEntity = new PaymentInputEntity(
                    {
                        authUid: authStore.currentUser?.uid,
                        mobileNumber: props.phoneNumber ?? '',
                        score: 0,
                        product: props.product,
                        imei: props.imei,
                        payment: new PaymentEntity({
                            method: 'subscription',
                            creditCard: new CreditCardEntity({
                                number: values.number,
                                name: values.name,
                                expirationDate: values.expiry,
                                cvv: values.cvv
                            })
                        })
                    })

                sendPayment.execute(
                    paymentInputEntity
                ).then(() => {
                    props.onSuccess?.();
                }).catch(() => {
                    props.onError?.();
                }).finally(() => {
                    setLoading(false);
                })
            }
        }
    })

    const errors = form.errors;
    const touched = form.touched;
    const handleSubmit = form.handleSubmit;
    const handleChange = form.handleChange
    const isValid = form.isValid

    function regexCC(value: string): FlagType {
        const regexList = [
            new CardRegex('amex', /^3[47][0-9]{13}$/),
            new CardRegex('visa', /^4[0-9]{12}(?:[0-9]{3})?$/),
            new CardRegex('mastercard', /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[01][0-9]{13}|720[0-9]{12}))$/),
            new CardRegex('diners', /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/),
            new CardRegex('elo', /^((636368|438935|504175|451416|636297|5067|4576|4011|506699)\d{10,13})$/),
            new CardRegex('discover', /^6(?:011|5[0-9]{2})[0-9]{12}$/),
            new CardRegex('jcb', /^(?:2131|1800|35\d{3})\d{11}$/)
        ]
        const tmp = value.replace(/\s/g, '');
        for (const card of regexList) {
            if (card.regex.test(tmp)) {
                return card.name;
            }
        }
        return undefined;
    }

    function handleNumberChange(value?: string | undefined) {
        const flag = regexCC(value ?? '');
        setFlag(flag);
    }

    return {
        errors,
        touched,
        handleSubmit,
        handleChange,
        isValid,
        handleNumberChange,
        flag,
        number: form.values.number,
        name: form.values.name,
        expiry: form.values.expiry,
        cvv: form.values.cvv,
        loading,
    }
}

export default useCheckoutController;