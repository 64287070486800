
function IconElo() {
    return (<svg width="39" height="16" viewBox="0 0 39 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.1434 8.78186C10.7484 10.6856 9.03376 12.1172 6.97862 12.1172C6.5071 12.1172 6.05324 12.0416 5.62978 11.9023L4.68579 14.6721C5.4062 14.9089 6.177 15.037 6.97862 15.037C10.4719 15.037 13.3859 12.6027 14.0573 9.3678L11.1434 8.78186Z" fill="white" />
        <path fill-rule="evenodd" clipRule="evenodd" d="M28.0814 2.52673V10.5353L29.5186 11.1113L28.8387 12.6909L27.4175 12.1196C27.0979 11.9856 26.8816 11.7814 26.7173 11.5509C26.5596 11.3149 26.4426 10.9925 26.4426 10.5573V2.52673H28.0814ZM17.1817 8.47889C17.2174 6.18869 19.1677 4.36054 21.5344 4.39563C23.543 4.42609 25.2073 5.78658 25.6445 7.59609L17.8756 10.8069C17.4243 10.1398 17.1679 9.33721 17.1817 8.47889ZM18.9593 8.79998C18.9486 8.70389 18.9411 8.60537 18.9439 8.50596C18.9662 7.15665 20.1145 6.07997 21.5088 6.10233C22.2678 6.11214 22.9417 6.44821 23.3972 6.96836L18.9593 8.79998ZM23.2337 10.2868C22.7655 10.7273 22.1305 10.9956 21.4295 10.986C20.9489 10.9782 20.5037 10.8391 20.1251 10.6073L19.1866 12.0533C19.8293 12.4462 20.5872 12.6786 21.4042 12.6909C22.5935 12.708 23.6775 12.2562 24.4657 11.5103L23.2337 10.2868ZM34.0628 6.10232C33.7828 6.10232 33.5138 6.14607 33.2624 6.22766L32.7032 4.60725C33.13 4.46943 33.5871 4.39457 34.0628 4.39457C36.1385 4.39457 37.8701 5.82037 38.2672 7.71438L36.5361 8.05564C36.3028 6.94096 35.2841 6.10232 34.0628 6.10232ZM31.2206 11.6501L32.3903 10.371C31.8678 9.92382 31.5389 9.27063 31.5389 8.54258C31.5389 7.81526 31.8678 7.1624 32.3898 6.71549L31.2192 5.43643C30.3318 6.1964 29.7727 7.30686 29.7727 8.54258C29.7727 9.77984 30.3322 10.8899 31.2206 11.6501ZM34.0627 10.9837C35.2829 10.9837 36.3014 10.1458 36.536 9.03277L38.2666 9.37549C37.8676 11.2675 36.1365 12.6913 34.0627 12.6913C33.5867 12.6913 33.129 12.6162 32.701 12.4778L33.2616 10.8579C33.5133 10.9393 33.7826 10.9837 34.0627 10.9837Z" fill="white" />
        <path d="M2.15954 13.4732L3.90482 11.2197C3.12577 10.4318 2.63408 9.28136 2.63408 7.99907C2.63408 6.71773 3.12532 5.56734 3.90413 4.77996L2.15794 2.52673C0.834191 3.86548 0 5.82102 0 7.99907C0 10.1782 0.835411 12.1345 2.15954 13.4732Z" fill="white" />
        <path d="M5.62843 4.09487C6.05186 3.95604 6.50484 3.88067 6.97616 3.88067C9.03311 3.88067 10.7489 5.31401 11.1425 7.21809L14.0573 6.63506C13.3885 3.39895 10.4727 0.962952 6.97616 0.962952C6.17564 0.962952 5.40509 1.09088 4.68579 1.32655L5.62843 4.09487Z" fill="white" />
    </svg>
    )
}

export default IconElo;