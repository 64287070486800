import { DrawerHeader, DrawerPanel } from "gosafe";
import ProfileCertificates from "./profile_certificates";
import { useDrawer } from "gosafe/molecules/drawer/drawer_provider";


function MyOrdersDrawer() {

    const { closeDrawer } = useDrawer()

    return (
        <div>
            <DrawerPanel
                name="Certificado de seguro"
                showBackButton={false}
                showCloseButton
                onBackButton={closeDrawer}
                onCloseButton={closeDrawer}
            >{<ProfileCertificates />}</DrawerPanel>
        </div >
    )
}

export default MyOrdersDrawer;
