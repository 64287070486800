import purchaseReducer, {PurchaseState} from "./purchase_state";
import {useParams, useNavigate} from "react-router-dom";
import {decompressFromEncodedURIComponent} from "lz-string";
import {useFirebase} from "../../../bindings/firebase_binding";
import {getAuth} from "firebase/auth";
import {useAuthentication} from "../../authentication/authentication_bindings";
import {useEffect, useReducer, useState} from "react";
import {useHttpClient} from "../../../bindings/http_binding";
import {useGetUserByIdUsecase} from "../get_user_by_id_context";
import {useGetTicketByIdUsecase} from "../get_ticket_by_user_context";

function setChooseProductStep(): void {
  const state = history.state.usr?.purchase as PurchaseState;
  history.state.usr.purchase = new PurchaseState({
    ...state,
    currentStep: 'CHOOSE_PRODUCT'
  });
}

function setAppState(): void {
  const {appState} = useParams();

  if (appState) {
    const decompressedAppState = decompressFromEncodedURIComponent(appState)
    history.replaceState(JSON.parse(decompressedAppState), '', `${window.location.origin}/purchase`);
    setChooseProductStep()
  }
}

export function usePurchasePageController(){
  const app = useFirebase();
  const auth = getAuth(app);
  const { isLogged, currentUser } = useAuthentication();
  const [loading, setLoading] = useState(true);
  setAppState();
  const [purchase, dispatch] = useReducer(purchaseReducer, history.state?.usr?.purchase ?? new PurchaseState({}));

  const navigate = useNavigate();
  const httpClient = useHttpClient();

  useEffect(() => {
    navigate('/purchase', { state: { purchase } })
  }, [purchase])

  const getUserById = useGetUserByIdUsecase();
  const getUserTicket = useGetTicketByIdUsecase();

  const hasTicketActive = async (): Promise<boolean> => {
    try {
      await getUserTicket.execute('active');
      return true;
    } catch (e) {
      return false;
    }
  }

  const hasTicketPaymentPending = async (): Promise<boolean> => {
    try {
      await getUserTicket.execute('payment_pending');
      return true;
    } catch (e) {
      return false;
    }
  }

  const fetchData = async () => {
    setLoading(true)
    if (isLogged) {
      try {
        const uid = currentUser?.uid ?? '';
        const user = await getUserById.execute(uid);
        if (user.have_ticket === true) {
          if (await hasTicketActive()) {
            dispatch({ type: 'SET_TICKET_ENABLED', customer: user });
          } else if (await hasTicketPaymentPending()) {
            dispatch({ type: 'SET_PAYMENT_SUCCESS', customer: user });
          } else {
            dispatch({ type: 'LOAD_CUSTOMER', customer: user });
          }
        } else {
          dispatch({ type: 'LOAD_CUSTOMER', customer: user });
        }
      } catch {
        dispatch({ type: 'CLEAN_CUSTOMER' });
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [isLogged]);

  const currentStep = purchase.currentStep;
  
  return {
    purchase,
    loading,
    currentStep,
    dispatch,
    httpClient,
    auth,
    navigate,
    fetchData,
    isLogged
  } 
}