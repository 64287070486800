export default function ImeiLocation() {
  return (
    <div className="flex flex-col gap-6 pt-9 pb-32 md:pb-0">
      <div className="flex w-full h-[230px] rounded-xl bg-neutral-100 justify-center items-start">
        <img src={"/images/imei-location.png"} alt="Lugar onde fica o imei" width={215} />
      </div>
      <h3 className="font-medium text-xl">Configuração do aparelho</h3>
      <div className="flex flex-col gap-6">
        <div className="space-x-5 flex items-center">
          <div className="w-10 h-10 p-6 flex justify-center items-center bg-neutral-100 rounded-full">1</div>
          <p className="text-sm">Vá até as configurações do seu aparelho e escolha a opção Sobre o Telefone, depois Status. Agora clique em informações do IMEI.</p>
        </div>
        <div className="space-x-5 flex items-center">
          <div className="w-10 h-10 p-6 flex justify-center items-center bg-neutral-100 rounded-full">2</div>
          <p className="text-sm">Pressione o número por algum tempo até aparecer a opção Copiar. Toque para copiar o número.</p>
        </div>
        <div className="space-x-5 flex items-center">
          <div className="w-10 h-10 p-6 flex justify-center items-center bg-neutral-100 rounded-full">3</div>
          <p className="text-sm">Retorne para o app da Exa e cole o número copiado no campo de inserção indicado.</p>
        </div>
        <div className="space-x-5 flex items-center">
          <div className="w-10 h-10 p-6 flex justify-center items-center bg-neutral-100 rounded-full">4</div>
          <p className="text-sm">Caso o seu celular tenha mais de um código IMEI, utilize sempre o primeiro.</p>
        </div>
      </div>
    </div>
  )
}
