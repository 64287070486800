import DrawerHeader from "./drawer_header";

export interface DrawerPanelProps {
  name: string;
  showBackButton: boolean;
  showCloseButton: boolean;
  onCloseButton?: () => void;
  onBackButton?: () => void;
  step?: number;
  children?: React.ReactNode;
}

export default function DrawerPanel({
  name,
  showBackButton,
  onBackButton,
  onCloseButton,
  showCloseButton,
  children,
}: DrawerPanelProps) {
  return (
    <div>
      <div className="sticky top-0 z-10 pl-2">
        <DrawerHeader
          title={name}
          showBackButton={showBackButton}
          onBackButton={onBackButton}
          onClose={onCloseButton}
          showCloseButton={showCloseButton}
        />
      </div>
      <div className="px-4">
        {children && children}
      </div>
    </div>
  );
}
