import { IconFillCrown, IconOutArticle, IconOutLogout } from "gosafe";
import { BackButton } from "gosafe/molecules/buttons";
import DropdownMenu, { OptionMenuProps } from "gosafe/molecules/dropdown_menu";
import { useDrawer } from "gosafe/molecules/drawer/drawer_provider";
import ProfileDrawer from "./profile_drawer";
import MyOrdersDrawer from "./my_orders_drawer";

interface TestefoneHeaderThinProps {
  httpClient?: any;
  authenticated?: boolean;
  onSignOut?: () => void;
  onBack?: () => void;
}

export default function TestefoneHeaderThin({
  httpClient,
  authenticated = true,
  onSignOut,
  onBack,
}: TestefoneHeaderThinProps) {
  const { openDrawer } = useDrawer()

  const className = [
    "flex justify-between items-center p-4"
  ];

  if (authenticated) {
    className.push("bg-neutral-100");
  } else {
    className.push("bg-primary");
  }

  const optionsMenu: OptionMenuProps[] = [
    {
      title: "Perfil",
      icon: <IconFillCrown />,
      iconPosition: "left",
      onTap: () => {
        openDrawer(<ProfileDrawer httpClient={httpClient} />)
      },
    },
    {
      title: "Certificado Seguro",
      icon: <IconOutArticle />,
      iconPosition: "left",
      onTap: () => {
        openDrawer(<MyOrdersDrawer />)
      },
    },
    {
      title: "Sair",
      icon: <IconOutLogout />,
      iconPosition: "right",
      onTap: () => {
        onSignOut?.();
      },
    },
  ];


  return (
    <div data-testid='TestefoneHeaderThin' className={className.join(" ")}>
      <div className="inline md:hidden">
        <BackButton color={authenticated ? "text-primary" : "text-neutral-100"} onClick={onBack} padding='16px 16px' />
      </div>
      <div>
        <a href="/">
          <img src={`/images/${authenticated ? 'logo-testefone-light' : 'logo-testefone'}.png`} alt="Logo testefone" width={160} />
        </a>
      </div>
      {authenticated && <DropdownMenu optionsMenu={optionsMenu} />}
    </div >
  );
}
