import AuthState, { IAuthState } from "core/authentication/auth_state";
import { ReactNode, createContext, useContext } from "react";
import SendCodeContext from "./send_code_context";
import ValidateCodeContext from "./validate_code_context";
import SendCodeUsecaseImpl from "./domain/usecases/send_code_usecase";
import ValidateCodeUsecaseImpl from "./domain/usecases/validate_code_usecase";
import { IHttpClient } from "core/protocols/http_client";
import ValidateCodeUsecaseAutomation from "./domain/usecases/validate_code_usecase_automation";
import SendCodeUsecaseAutomation from "./domain/usecases/send_code_usecase_automation";

const OwnershipValidationContext = createContext<IAuthState | undefined>(undefined)

interface OwnershipValidationProps {
    httpClient: IHttpClient
    children: ReactNode
}

function OwnershipValidationBindings({ children, httpClient }: OwnershipValidationProps) {
    const authState = AuthState({ loading: false });

    const validateCodeUsecase = (process.env.REACT_APP_ENV === "AUTOMATION") ? new ValidateCodeUsecaseAutomation() : new ValidateCodeUsecaseImpl(httpClient)
    const sendCodeUsecase = (process.env.REACT_APP_ENV === "AUTOMATION") ? new SendCodeUsecaseAutomation() : new SendCodeUsecaseImpl(httpClient)

    return (
        <SendCodeContext.Provider value={sendCodeUsecase}>
            <ValidateCodeContext.Provider value={validateCodeUsecase}>
                <OwnershipValidationContext.Provider value={authState}>{children}</OwnershipValidationContext.Provider>
            </ValidateCodeContext.Provider>
        </SendCodeContext.Provider>

    )
}

export default OwnershipValidationBindings