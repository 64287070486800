export default function IconOutArticle() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.90131 4.66683C5.58834 4.66683 5.33464 4.92053 5.33464 5.2335C5.33464 5.54646 5.58834 5.80016 5.90131 5.80016H8.76796C9.08093 5.80016 9.33463 5.54646 9.33463 5.2335C9.33463 4.92053 9.08093 4.66683 8.76796 4.66683H5.90131Z"
        fill="#004BC1"
      />
      <path
        d="M5.33464 8.00018C5.33464 7.68722 5.58834 7.43351 5.90131 7.43351H10.1013C10.4143 7.43351 10.668 7.68722 10.668 8.00018C10.668 8.31314 10.4143 8.56684 10.1013 8.56684H5.90131C5.58834 8.56684 5.33464 8.31314 5.33464 8.00018Z"
        fill="#004BC1"
      />
      <path
        d="M5.90131 10.2002C5.58834 10.2002 5.33464 10.4539 5.33464 10.7668C5.33464 11.0798 5.58834 11.3335 5.90131 11.3335H6.76796C7.08093 11.3335 7.33464 11.0798 7.33464 10.7668C7.33464 10.4539 7.08093 10.2002 6.76796 10.2002H5.90131Z"
        fill="#004BC1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.66797 3.60016C2.66797 2.34832 3.68279 1.3335 4.93463 1.3335H11.068C12.3198 1.3335 13.3346 2.34832 13.3346 3.60016V12.4002C13.3346 13.652 12.3198 14.6668 11.068 14.6668H4.93463C3.68279 14.6668 2.66797 13.652 2.66797 12.4002V3.60016ZM4.93463 2.46683H11.068C11.6939 2.46683 12.2013 2.97424 12.2013 3.60016V12.4002C12.2013 13.0261 11.6939 13.5335 11.068 13.5335H4.93463C4.30872 13.5335 3.8013 13.0261 3.8013 12.4002V3.60016C3.8013 2.97424 4.30872 2.46683 4.93463 2.46683Z"
        fill="#004BC1"
      />
    </svg>
  );
}
