import { IHttpClient } from "core/protocols/http_client";
import OrderEntity from "features/purchase/domain/entities/order_entity";
import * as Sentry from "@sentry/react";

export interface IGetLastOrderByUserUsecase {
  execute() : Promise<OrderEntity>
}

export default class GetLastOrderByUserUsecaseImpl implements IGetLastOrderByUserUsecase {
  private httpClient: IHttpClient;

  constructor (httpClient: IHttpClient) {
    this.httpClient = httpClient
  }

  async execute(): Promise<OrderEntity> {
    try {
      const response = await this.httpClient.get<OrderEntity>(`/bff/v1/orders/last/`)
      return response.data;
    } catch(error) {
  Sentry.captureException(error, {
    tags: {
      'feature.purchase': "GetLastOrderByUserUsecaseImpl",
      'api.endpoint': `/bff/v1/orders/last/`,
    },
  });
      throw error;
    }
  }
}