import { useFormik } from "formik";
import {
  Divider,
  DrawerPanel,
  ExaLoading,
  IconArrowRight,
  SecondaryButton,
  Text,
  TextLink,
} from "gosafe";
import TextField from "gosafe/atoms/text_field/text_field";
import * as Yup from "yup";

import {
  ImeiCheckProps,
  useImeiCheckController,
} from "./imei_check_controller";
import DrawerProvider, { useDrawer } from "gosafe/molecules/drawer/drawer_provider";
import ImeiLocation from "./components/imei_location";
import ImeiDefinition from "./components/imei_definition";

function ImeiCheck(props: ImeiCheckProps) {
  const {
    imeiLoading,
    check
  } = useImeiCheckController(props);

  const { openDrawer, closeDrawer } = useDrawer()

  const form = useFormik({
    initialValues: {
      imei: "",
    },
    validationSchema: Yup.object({
      imei: Yup.string()
        .required("O IMEI é obrigatório")
        .min(15, "O número do IMEI deve ter no mínimo 15 dígitos"),
    }),
    onSubmit: values => {
      check(values.imei).then(isValid => {
        if (!isValid) {
          form.setErrors({
            imei: "O IMEI digitado é inválido. Revise a digitação.",
          });
        }
      });
    },
  });

  return (
    <form onSubmit={form.handleSubmit}>
      {imeiLoading && (
        <div className="flex flex-col items-center gap-4 py-4">
          <ExaLoading />
          <Text fontWeight="font-medium" color="text-neutral-10">
            Validando IMEI...
          </Text>
        </div>
      )}

      {!imeiLoading && (
        <div>
          <TextField
            name="imei"
            textLabel="IMEI"
            mask={"99 9999999 999999999"}
            hintText="00 0000000 000000000"
            errorMessage={form.errors.imei}
            onChange={form.handleChange}
            value={form.values.imei}
            showErros={form.touched.imei}
          />

          <Divider />
          <TextLink
            linkTo="#"
            label="Onde encontrar o IMEI?"
            onClick={() => {
              openDrawer(<DrawerPanel
                name="Onde encontro o código IMEI?"
                showBackButton={false}
                showCloseButton
                onCloseButton={closeDrawer}
              >
                <ImeiLocation />
              </DrawerPanel>);
            }}
          />
          <Divider />
          <TextLink
            linkTo="#"
            label="O que é o código IMEI?"
            onClick={() => {
              openDrawer(<DrawerPanel
                name="O que é o código IMEI?"
                showBackButton={false}
                showCloseButton
                onCloseButton={closeDrawer}
              >
                <ImeiDefinition />
              </DrawerPanel>);
            }}
          />
          <Divider />

          <div className="pt-4">
            <SecondaryButton
              enabled={!imeiLoading}
              infinity={true}
              text="Ir para pagamento"
              iconRight={<IconArrowRight />}
              showIconRight={true}
              onClick={form.submitForm}
            />
          </div>
        </div>
      )
      }
    </form >
  );
}

export default ImeiCheck;
