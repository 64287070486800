import { db } from 'core/config/firebase_config.tsx';
import FeatureToggleFirestoreDatasourceImpl from 'features/feature_toogle/data/feature_toggle_firestore_datasource.ts';
import FeatureToggleUsecaseImpl from 'features/feature_toogle/domain/usecases/get_feature_toggle.ts';
import forge from 'node-forge';

/** criptografia */ 
function generateKeyAes() {
  return {
    aesKey: forge.random.getBytesSync(32),
    initializationVector: forge.random.getBytesSync(16)
  }
}

function encryptAes(data, aesKey, initializationVector) {
  const cipher = forge.cipher.createCipher('AES-CBC', aesKey);
  cipher.start({ iv: initializationVector });
  cipher.update(forge.util.createBuffer(data, 'raw'));
  cipher.finish();
  return forge.util.encode64(cipher.output.getBytes());
}

function parsePublicKey(publicKeyPem) {
  return forge.pki.publicKeyFromPem(publicKeyPem);
}

function encryptRsa(data, publicKeyPem) {
  return forge.util.encode64(parsePublicKey(publicKeyPem).encrypt(data, 'RSA-OAEP'));
}

function encrypt(object, publicKeyPem) {

  const data = JSON.stringify(object);

  const { aesKey, initializationVector } = generateKeyAes();

  const encryptedData = encryptAes(data, aesKey, initializationVector);
  const encryptedAesKey = encryptRsa(aesKey, publicKeyPem);
  const encryptedIv = encryptRsa(initializationVector, publicKeyPem);

  const json = JSON.stringify({
    data: encryptedData,
    key: encryptedAesKey,
    iv: encryptedIv
  });

  const jsonBase64 = forge.util.encode64(json);

  return jsonBase64;
}
/** criptografia */ 

export async function Sales(data) {

  const featureToggleDatasource = new FeatureToggleFirestoreDatasourceImpl(db);
  const featureToggleUsecase = new FeatureToggleUsecaseImpl(featureToggleDatasource);

  const encriptedDataEnabled = await featureToggleUsecase.isEnabled('encrypt_body');
  const dataToRequest = encriptedDataEnabled ?
    {
      data: encrypt(data, process.env.REACT_APP_PUBLIC_KEY)
    }
    : data;

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(dataToRequest)
  };

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const response = await fetch(`${baseUrl}/bff/sales`, requestOptions)

  if (!response.ok) {
    return { status: false, message: response.message, code_status: response.status };
  }

  const returnData = await response.json();

  return {
    status: true,
    data: returnData
  };
}

export async function ticket(id) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const response = await fetch(`${baseUrl}/bff/sales/ticket/${id}`, requestOptions)

  if (!response.ok) {
    return {
      status: false,
      message: response.message,
      code_status: response.status
    };
  }

  const dataInfo = await response.json();

  return {
    status: true,
    data: dataInfo
  };
}
