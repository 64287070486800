import { IHttpClient } from "core/protocols/http_client";
import cpfValidator from "core/validators/cpf_validator";
import { useAuthentication } from "features/authentication/authentication_bindings";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { GetAddressByCepUsecaseImpl } from "../../domain/usecases/get_address_by_cep_usecase";
import UpdateProfileUsecaseImpl from "../../domain/usecases/update_user_usecase";
import { useGetUserByIdUsecase } from "../../get_user_by_id_context";
import isEquals from "core/utils/is_equals";
import zipCodeValidator from "core/validators/zip_code_validator";

export interface IProfilePersonalInfoController {
    httpClient: IHttpClient;
    onBack?: () => void;
}

interface ProfileFormValueType {
    fullname: string;
    cpf: string;
    phone: string;
    email: string;
    zip_code: string;
    address: string;
    number: string | undefined;
    complement: string | undefined;
    neighborhood: string;
    city: string;
    uf: string;
}

export default function useProfilePersonalInfoController({
    httpClient,
    onBack
}: IProfilePersonalInfoController) {
    const getUserById = useGetUserByIdUsecase();
    const updateProfileUsecase = new UpdateProfileUsecaseImpl(httpClient);
    const getAddressByCep = new GetAddressByCepUsecaseImpl(httpClient);
    const { currentUser, authMethod } = useAuthentication();

    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [initialValue, setInitialValue] = useState<ProfileFormValueType | undefined>(undefined);
    const [initializing, setInitializing] = useState(true);
    const [withoutNumber, setWithoutNumber] = useState(false);
    const [withoutComplement, setWithoutComplement] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);

    const form = useFormik<ProfileFormValueType>({
        initialValues: {
            fullname: '',
            cpf: '',
            phone: '',
            email: '',
            zip_code: '',
            address: '',
            number: undefined,
            complement: undefined,
            neighborhood: '',
            city: '',
            uf: '',
        },
        validationSchema: Yup.object().shape({
            fullname: Yup.string().optional(),
            cpf: Yup.string().optional(),
            phone: Yup.string().optional(),
            email: Yup.string().optional(),
            zip_code: Yup.string()
                .required("O CEP é obrigatório!")
                .test("zip_code", "CEP inválido!", value => {
                    let onlyNumbers = value.replace(/\D/g, "");
                    return zipCodeValidator(onlyNumbers)
                }),
            address: Yup.string().required("Endereço é obrigatório!"),
            number: Yup.number().positive("O número tem que ser positivo").test("number", "Número é obrigatório!", value => {
                if (withoutNumber) return true;
                return value !== undefined;
            }),
            complement: Yup.string().test("complement", "Complemento é obrigatório!", value => {
                if (withoutComplement) return true;
                return value !== undefined && value !== '';
            }),
            neighborhood: Yup.string().required("Bairro é obrigatório!"),
            city: Yup.string().required("Cidade é obrigatório!"),
            uf: Yup.string().required("UF é obrigatório!"),
        }),
        onSubmit: async (values) => {
            setLoadingUpdate(true);
            const success = await updateProfile(values);
            if (success) {
                onBack?.();
            } else {
                // TODO: Tratar erro
            }
            setLoadingUpdate(false);
        }
    });

    async function updateProfile(values: any): Promise<boolean> {
        const payload = {
            authUid: currentUser?.uid ?? '',
            authMethod: authMethod ?? '',
            email: currentUser?.email ?? '',
            fullname: values.fullname ?? '',
            profile: {
                cpf: values.cpf,
                phone: values.phone,
                address: {
                    cep: values.zip_code,
                    logradouro: values.address,
                    numero: values.number,
                    complemento: values.complement,
                    bairro: values.neighborhood,
                    localidade: values.city,
                    uf: values.uf,
                },
            },
        };
        return updateProfileUsecase.execute(payload).then(() => {
            return true;
        }).catch(() => {
            return false;
        })
    };

    useEffect(() => {
        if (withoutComplement) {
            form.setFieldValue('complement', "")
            form.setTouched({
                ...form.touched,
                complement: true
            })
        }
        if (withoutNumber) {
            form.setFieldValue('number', "")
            form.setTouched({
                ...form.touched,
                number: true
            })
        }
    }, [withoutComplement, withoutNumber]);

    useEffect(() => {
        const fetchData = async () => {
            setInitializing(true);
            try {
                const uid = currentUser?.uid ?? "";
                const result = await getUserById.execute(uid);
                const userData = {
                    fullname: result.fullname || "",
                    cpf: result.cpf || "",
                    email: currentUser?.email || "",
                    phone: result.phone || "",
                    zip_code: result.address?.zipcode || "",
                    address: result.address?.name || "",
                    number: result.address?.number || undefined,
                    complement: result.address?.complement || "",
                    neighborhood: result.address?.neighborhood || "",
                    city: result.address?.city || "",
                    uf: result.address?.uf || "",
                }
                setWithoutComplement(userData.complement === undefined || userData.complement === "");
                setWithoutNumber(userData.number === undefined || userData.number === "");
                setInitialValue(userData);
                form.setValues(userData, true);
            } catch (error) {
            } finally {
                setInitializing(false);
            }
        };
        fetchData();
    }, [currentUser]);

    useEffect(() => {
        if (initialValue !== undefined) {
            setHasChanges(!isEquals(form.values, initialValue!))
        }
    }, [form.values])

    async function onZipcode(zipCode: string) {
        if (zipCodeValidator(zipCode)) {
            try {
                const address = await getAddress(zipCode.replace(/\D/g, ""));
                form.setValues({
                    ...form.values,
                    zip_code: zipCode,
                    address: address.logradouro ?? "",
                    city: address.localidade ?? "",
                    complement: zipCode === initialValue?.zip_code ? initialValue.complement : address.complemento ?? "",
                    neighborhood: address.bairro ?? "",
                    uf: address.uf ?? "",
                    number: zipCode === initialValue?.zip_code ? initialValue.number : "",
                });

                if (zipCode === initialValue?.zip_code) {
                    setWithoutComplement(initialValue?.complement === undefined || initialValue?.complement === "");
                    setWithoutNumber(initialValue?.number === undefined || initialValue?.number === "");
                } else {
                    setWithoutComplement(false);
                    setWithoutNumber(false);
                }
            } catch {
                form.setValues({
                    ...form.values,
                    zip_code: zipCode,
                    address: "",
                    city: "",
                    number: "",
                    complement: "",
                    neighborhood: "",
                    uf: "",
                });
            }
        }
    }

    function getAddress(query: string) {
        return getAddressByCep.execute(query)
            .then((item: any) => ({
                cep: item.cep,
                logradouro: item.logradouro,
                bairro: item.bairro,
                complemento: item.complemento,
                localidade: item.localidade,
                uf: item.uf,
            }))
            .catch((error) => {
                throw error;
            });
    }

    return {
        form,
        withoutNumber,
        setWithoutNumber,
        withoutComplement,
        setWithoutComplement,
        initializing,
        loadingUpdate,
        hasChanges,
        onZipcode
    };
}
