import { IHttpClient } from "core/protocols/http_client";
import OrderEntity from "features/purchase/domain/entities/order_entity";
import * as Sentry from "@sentry/react";

export interface IGetPhoneInfoByOrderUsecase {
  execute(order_id: string): Promise<OrderEntity>;
}

export default class GetPhoneInfoByOrderUsecaseImpl implements IGetPhoneInfoByOrderUsecase {
  private httpClient: IHttpClient;

  constructor(httpClient: IHttpClient) {
    this.httpClient = httpClient
  }

  async execute(order_id: string): Promise<OrderEntity> {
    try {
      const response = await this.httpClient.get<OrderEntity>(`/bff/v1/order/${order_id}`)
      return response.data;
    } catch(error) {
      Sentry.captureException(error, {
        tags: {
          'feature.purchase': "GetPhoneInfoByOrderUsecaseImpl",
          'api.endpoint': `/bff/v1/order/${order_id}`,
        },
      });
      throw error;
    }
  }
}