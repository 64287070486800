import { InfinityLoading } from "gosafe/atoms";
import IconExclamationMark from "gosafe/atoms/icons/icon_exclamation_mark";


export interface ExaLoadingProps {
    state?: 'loading' | 'success' | 'error';
  }


export default function ExaLoading({ state = 'loading'}: ExaLoadingProps) {

    let color1 = '';
    let color2 = '';
    let circleColor = '';

    switch (state) {
        case 'success':
            color1= 'bg-loading-success-layer-1'
            color2= 'bg-loading-success-layer-2'
            circleColor = 'bg-secondary'
            break;
        case 'error':
            color1= 'bg-loading-error-layer-1'
            color2= 'bg-loading-error-layer-2'
            circleColor = 'bg-error'
            break;

        default:
            color1= 'bg-neutral-60'
            color2= 'bg-neutral-40'
            circleColor = 'bg-neutral-40'
            break;
    }

    const animatePing1 = [
        'animate-ping-slow',
        'absolute',
        'flex',
        color1,
        'rounded-full',
        'ml-1',
        'mt-1',
        'w-10',
        'h-10'
    ]

    const animatePing2 = [
        'animate-ping-slow',
        'absolute',
        'flex',
        color2,
        'rounded-full',
        'ml-2',
        'mt-2',
        'w-8',
        'h-8'
    ]

    const animateCircle = [
        'relative',
        'inline-flex',
        circleColor,
        'w-12',
        'h-12',
        'rounded-full',
        'p-2',
        'animate-none'
    ]

    return (
        <div data-testid="Loader" className="relative w-12 h-12">
            <div className={animatePing2.join(" ")}></div>
            <div className={animatePing1.join(" ")}></div>
            <div className={animateCircle.join(" ")}>
                {(state === 'loading' || state=== 'success')  && <InfinityLoading strokeColor="text-neutral-100" /> }
                {state === 'error' && <div className="w-full h-full flex items-center justify-center"><IconExclamationMark color="white" width={8} height={19} /></div>}
            </div>
        </div>
    )
}
