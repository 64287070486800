import { PlanModel, ProductModel } from "models";

export class GetProductInput {
  manufacturer: string;
  model: string;
  storage: string;

  constructor(manufacturer: string, model: string, storage: string) {
    this.manufacturer = manufacturer;
    this.model = model;
    this.storage = storage;
  }

  get data() {
    return JSON.stringify({
      manufacturer: this.manufacturer,
      model: this.model,
      storage: this.storage,
    });
  }
}

export interface IGetProductsUsecase {
  execute(input: GetProductInput): Promise<ProductModel[]>;
}

export class GetProductsUsecaseImpl implements IGetProductsUsecase {
    async execute(input: GetProductInput): Promise<ProductModel[]> {
        return fetch(`${process.env.REACT_APP_BASE_URL}/bff/v1/products`, {
            method: "POST",
            body: input.data,
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "X-App-Version": "1"
            }
        }).then(async (result) => {
            if (result.ok) {
                return result.json().then((data) => {
                    return data.map((item: any) => {
                        return new ProductModel(
                            item.amount,
                            item.brand_id,
                            item.id,
                            item.model_id,
                            new PlanModel(
                                item.plan.amount,
                                item.plan.category,
                                item.plan.code,
                                item.plan.id,
                                item.plan.manual_url,
                                item.plan.max_indemnification,
                                item.plan.name
                            ),
                            item.plan_code,
                            item.plan_description,
                            item.plan_name,
                            item.smartphone_model_name
                        )
                    })
                })
            }
            return []
        }).catch((error) => {
            throw error
        });
    }
}
