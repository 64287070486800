import { FooterSection, TryNowSection } from "components";
import Chatbot from "features/chatbot";
import OwnershipValidation from 'features/ownership_validation';
import { ExaLoading } from 'gosafe';
import { BasePage } from "gosafe/templates";
import UrlApps from "pages/landing_page/url_apps";
import { ReactNode } from 'react';
import PurchaseTemplate from './components/purchase_template';
import {
  StepAwaitPayment,
  StepCertificateActive,
  StepChoosePhone,
  StepChooseProduct,
  StepCreditCardForm,
  StepImeiCheck,
  StepProcessPayment,
  StepProfileForm,
} from './components/steps';
import TestefoneHeaderThin from "./components/testefone_header_thin";
import { usePurchasePageController } from "./purchase_page_controller";
import purchaseReducer from './purchase_state';

export default function PurchasePage() {
  const {
    purchase,
    loading,
    currentStep,
    dispatch,
    httpClient,
    auth,
    navigate,
    fetchData,
    isLogged
  } = usePurchasePageController();

  function buildStep(): ReactNode {
    switch (currentStep) {
      case 'REQUESTING_PAYMENT':
      case 'PAYMENT_SUCCESS':
        return (<StepProcessPayment onRefresh={() => {
          fetchData();
        }}/>)
      case 'PAYMENT_ERROR':
        return (<StepAwaitPayment onClick={() => {
          dispatch({type: 'ROLLBACK'});
        }}/>)
      case 'CONFIRM_PHONE_NUMBER':
        return <OwnershipValidation
          httpClient={httpClient}
          phone={purchase.customer?.phone}
          onNextStep={() => {
            dispatch({type: 'SET_PHONE_CHECKED'});
          }}
          onPreviousStep={() => {
            dispatch({type: 'ROLLBACK'});
          }}/>
      case 'TICKET_ENABLED':
        return <StepCertificateActive/>
      default:
        const steps: { [key: string]: ReactNode } = {
          ['CHOOSE_PHONE']: <StepChoosePhone
            httpClient={httpClient}
            onNext={(_, phone) => {
              dispatch({type: 'SET_PHONE', phone});
            }}
          />,
          ['CHOOSE_PRODUCT']: <StepChooseProduct
            phone={purchase.phone}
            product={purchase.product}
            setProduct={(product) => {
              dispatch({
                type: 'SELECT_PRODUCT',
                product: product
              })
            }}
            onNext={(product) => {
              if (!isLogged) {
                navigate("/signin", {state: {purchase: purchaseReducer(purchase, {type: 'SET_PRODUCT', product})}});
              } else {
                dispatch({type: 'SET_PRODUCT', product});
              }
            }}
          />,
          ['SET_PROFILE']: <StepProfileForm
            httpClient={httpClient}
            customer={purchase.customer}
            onNextStep={(profile) => {
              dispatch({
                type: 'SET_PROFILE', customer: {
                  ...purchase.customer,
                  cpf: profile.cpf.replace(/\D/g, ''),
                  birthday: profile.birthday,
                  phone: profile.phone.replace(/\D/g, ''),
                  fullname: profile.fullname,
                  address: {...profile.address},
                }
              });
            }}
          />,
          ['SET_IMEI']: <StepImeiCheck
            httpClient={httpClient}
            phone={purchase.phone}
            onNextStep={(result) => {
              dispatch({type: 'SET_IMEI', imei: result.imei});
            }}
          />,
          ['SET_CREDIT_CARD']: <StepCreditCardForm
            phoneNumber={purchase.customer?.phone}
            product={purchase.product}
            imei={purchase.imei}
            onSuccess={() => {
              dispatch({type: 'SET_PAYMENT_SUCCESS'});
            }}
            onError={() => {
              dispatch({type: 'SET_PAYMENT_ERROR'});
            }}
          />
        };
        return (<PurchaseTemplate
          currentStep={currentStep}
          phone={purchase.phone}
          onBack={() => {
            if (currentStep === 'CHOOSE_PHONE') {
              navigate('/');
            } else {
              dispatch({type: 'ROLLBACK'});
            }
          }}
          onRemovePhone={() => {
            dispatch({type: 'CLEAN_PHONE'});
          }}
          product={purchase.product}
          onRemoveProduct={() => {
            dispatch({type: 'CLEAN_PRODUCT'});
          }}
        >
          {steps[currentStep]}
        </PurchaseTemplate>)
    }
  }

  return (
    <BasePage
      data-testid="BasePage"
      header={
        <TestefoneHeaderThin
          httpClient={httpClient}
          authenticated={isLogged}
          onBack={() => {
            if (currentStep === 'CHOOSE_PHONE') {
              navigate('/');
            } else {
              dispatch({type: 'ROLLBACK'});
            }
          }}
          onSignOut={() => {
            auth.signOut().then(() => {
              dispatch({type: 'LOGOUT'});
              navigate('/');
            })
          }}
        />
      }
    >
      {loading &&
          <div className='flex flex-col gap-4 justify-center items-center h-[500px] text-primary'>
              <ExaLoading/>
          </div>}
      {!loading && buildStep()}

      {currentStep === 'TICKET_ENABLED' ? (
        <TryNowSection
          title="Baixe o app para acessar o certificado do seu seguro de onde estiver"
          active={true}
          urlApps={
            new UrlApps(
              "https://play.google.com/store/apps/details?id=br.com.exa.testefone.testefone",
              "https://apps.apple.com/br/app/testefone-avalie-seu-celular/id6459995038?l=en-GB"
            )
          }
        />
      ) : (
        <TryNowSection
          title="Baixe o app e receba ofertas especiais"
          urlApps={
            new UrlApps(
              "https://play.google.com/store/apps/details?id=br.com.exa.testefone.testefone",
              "https://apps.apple.com/br/app/testefone-avalie-seu-celular/id6459995038?l=en-GB"
            )
          }
        />
      )}

      <FooterSection/>
      <Chatbot primary={false}/>
    </BasePage>
  );
}
