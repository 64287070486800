import CustomerEntity from "./customer_entity";
import PaymentEntity from "./payment_entity";
import ProductEntity from "./product_entity";

class PaymentInputEntity {
  authUid?: string;
  mobileNumber?: string;
  product?: ProductEntity;
  score?: number;
  imei?: string;
  payment?: PaymentEntity;

  constructor(args: {
    authUid?: string,
    mobileNumber?: string,
    invoiceDate?: string,
    product?: ProductEntity,
    score?: number,
    imei?: string,
    appToken?: string,
    payment?: PaymentEntity,
    customer?: CustomerEntity
  }) {
    this.authUid = args.authUid;
    this.mobileNumber = args.mobileNumber;
    this.product = args.product;
    this.score = args.score;
    this.imei = args.imei;
    this.payment = args.payment;
  }

  toMap() : any {
    return {
      auth_uid: this.authUid,
      mobile_number: this.mobileNumber,
      score: this.score,
      imei: this.imei,
      product:  {
        amount: this.product?.amount,
        id: this.product?.id,
        name: this.product?.name,
        plan_code: this.product?.planCode
      },
      payment: this.payment?.toMap(),
      state: 'charge'
    }
  }
}

export default PaymentInputEntity;