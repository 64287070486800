import {IHttpClient} from "core/protocols/http_client";
import {ShortUrlResponseEntity} from "../entities/short_url_response_entity";
import {ShortUrlRequestEntity} from "../entities/short_url_request_entity";
import {ShortUrlApiResponse} from "../entities/short_url_api_response";

export interface IGetShortUrlUseCase {
  execute(url: string): Promise<ShortUrlResponseEntity>;
}

export default class GetShortUrlUseCase implements IGetShortUrlUseCase {
  private httpClient: IHttpClient;

  constructor(httpClient: IHttpClient) {
    this.httpClient = httpClient;
  }

  async execute(url: string): Promise<ShortUrlResponseEntity> {
    try {
      const result = await this.httpClient.post<ShortUrlApiResponse>('/bff/v1/shorten-url', new ShortUrlRequestEntity(url));
      return new ShortUrlResponseEntity(result.data);
    } catch (error) {
      throw error;
    }
  }
}