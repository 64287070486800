import { IHttpClient } from "core/protocols/http_client";

import AddressEntity from "../entities/address_entity";
import * as Sentry from "@sentry/react";

export interface IGetAddressByCepUsecase {
  execute(cep: string): Promise<AddressEntity>;
}

export class GetAddressByCepUsecaseImpl implements IGetAddressByCepUsecase {
  private httpClient: IHttpClient;

  constructor(httpClient: IHttpClient) {
    this.httpClient = httpClient;
  }

  execute(cep: string): Promise<any> {
    return this.httpClient.get(`/bff/v1/cep/${cep}`)
      .then(async response => {
        return await response.data
      })
      .catch(error => {
        console.log(error);
        Sentry.captureException(error, {
          tags: {
            'feature.profile': "GetAddressByCepUsecaseImplPurchase",
            'api.endpoint': `/bff/v1/cep/${cep}`,
          },
        });
        throw error;
      });
  }
}
