import DrawerPanel, { DrawerPanelProps } from "gosafe/molecules/drawer/drawer_panel";
import { useState } from "react";
import ProfileOptions from "./profile_options";
import ProfilePersonalInfo from "./profile_personal_info";
import ProfilePhoneInfo from "./profile_phone_info";
import { useDrawer } from "gosafe/molecules/drawer/drawer_provider";

function ProfileDrawer({ httpClient }: { httpClient?: any; }) {

    const { closeDrawer } = useDrawer()
    const [opened, setOpened] = useState(false);
    const [step, setStep] = useState(0);

    function handleToggle(value: boolean) {
        setOpened(value);
    }

    const optionsPanel: DrawerPanelProps[] = [
        {
            step: 0,
            name: "Perfil",
            showBackButton: false,
            showCloseButton: true,
            onBackButton: () => { },
            onCloseButton: closeDrawer,
            children: <ProfileOptions onTap={step => setStep(step)} />,
        },
        {
            step: 1,
            name: "Informaçoes pessoais",
            showBackButton: true,
            showCloseButton: true,
            onBackButton: () => setStep(0),
            onCloseButton: closeDrawer,
            children: <ProfilePersonalInfo httpClient={httpClient} onBack={() => {
                setStep(0)
            }} />,
        },
        {
            step: 2,
            name: "Dados do celular",
            showBackButton: true,
            showCloseButton: true,
            onBackButton: () => setStep(0),
            onCloseButton: closeDrawer,
            children: <ProfilePhoneInfo />,
        }
    ];

    return (
        <div>
            {step < optionsPanel.length ? (
                optionsPanel.map(
                    (item: DrawerPanelProps) =>
                        step === item.step && (
                            <DrawerPanel
                                name={item.name}
                                key={item.step}
                                showBackButton={item.showBackButton}
                                showCloseButton={item.showCloseButton}
                                onBackButton={item.onBackButton}
                                onCloseButton={item.onCloseButton}
                            >
                                {item.children}
                            </DrawerPanel>
                        )
                )
            ) : (
                <DrawerPanel
                    name="Não Encontrado"
                    showBackButton={true}
                    showCloseButton={false}
                    onBackButton={() => setStep(0)}
                    onCloseButton={() => handleToggle(!opened)}
                />
            )}
        </div>
    )
}

export default ProfileDrawer;
