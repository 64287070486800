import { useCallback, useEffect, useState } from "react";

import { DropdownFieldItem } from "gosafe/molecules";

import domainValidator from "core/validators/domain_validator";
import emailValidator from "core/validators/email_validator";
import { useFormik } from "formik";
import * as Yup from 'yup';
import BrandEntity from "../domain/entities/brand_entity";
import PhoneEntity from "../domain/entities/phone_entity";
import { IGetBrandsByNameUsecase } from "../domain";
import { IGetModelsByBrandAndNameUsecase } from "../domain";
import { IUpdateLeadUsecase } from "../domain/usecases/update_lead_usecase";

export interface BrandAndModelProps {
  onBrandChanged?: (value: DropdownFieldItem | undefined) => void;
  onModelChanged?: (value: DropdownFieldItem | undefined) => void;
  onTap?: (brand: BrandEntity, phone: PhoneEntity) => void;
}

export function useBrandAndModelController(
  getBrandsByNameUsecase: IGetBrandsByNameUsecase,
  getModelsByBrandAndNameUsecase: IGetModelsByBrandAndNameUsecase,
  updateLeadUsecase: IUpdateLeadUsecase,
  props: BrandAndModelProps
) {
  const [brand, setBrand] = useState<DropdownFieldItem | undefined>();
  const [phoneModel, setPhoneModel] = useState<DropdownFieldItem | undefined>();
  const [brands, setBrands] = useState<Array<BrandEntity>>([]);
  const [phoneModels, setPhoneModels] = useState<Array<PhoneEntity>>([]);
  const [brandLoading, setBrandLoading] = useState(false);
  const [phoneModelLoading, setPhoneModelLoading] = useState(false);
  const [sentLeadLoading, setSentLeadLoading] = useState(false);

  const form = useFormik({
    initialValues: {
      brand: '',
      model: '',
      email: '',
      opt_in: true,
    },
    validationSchema: Yup.object().shape({
      brand: Yup.string().required('Marca é obrigatório!'),
      model: Yup.string().required('Modelo é obrigatório!'),
      email: Yup.string()
        .email('A digitação está incorreta')
        .test("email", "A digitação do @dominio está incorreta!", value => {
          if (value && value?.includes('@')) {
            return domainValidator(value?.split("@")[1])
          }
        })
        .test("email", "A digitação do endereço de email está incorreta!", value => {
          if (value && value?.includes('@')) {
            return emailValidator(value?.split("@")[0])
          }
        })
        .test("email", "Endereço de email deve ser menor que 64 caracteres!", value => {
          if (value && value?.includes('@')) {
            return value?.split("@")[0].length <= 64
          }
        })
        .required('Email é obrigatorio!'),
      opt_in: Yup.boolean().default(true)
    }),
    isInitialValid: false,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (value) => {
      const b = brands.find(b => b.id === brand?.value);
      const m = phoneModels.find(m => m.id === phoneModel?.value);

      setSentLeadLoading(true);
      updateLeadUsecase.execute({
        email: value.email,
        manufacturer: value.brand,
        model: value.model,
        opt_in: value.opt_in
      }).then(() => {
        props.onTap?.(b!, m!)
      }).catch(() => {
        props.onTap?.(b!, m!)
      }).finally(() => {
        setSentLeadLoading(false);
      })
    },
  })

  const getBrands = useCallback((query?: string) => {
    setBrandLoading(true);
    getBrandsByNameUsecase
      .execute(query ?? "")
      .then((brands: Array<BrandEntity>) => {
        setBrands(brands);
        clearPhoneModels();
      }).catch(() => { 
        setBrands([]);
        clearPhoneModels();
      })
      .finally(() => {
        setBrandLoading(false);
      });
  }, [])

  const getPhoneModelsByBrand = useCallback((brandId: number, query?: string) => {
    if (query !== undefined) {
      setPhoneModelLoading(true);
      getModelsByBrandAndNameUsecase
        .execute(brandId, query)
        .then((phoneModels: Array<PhoneEntity>) => {
          setPhoneModels(phoneModels);
        }).catch(() => { 
          setPhoneModels([]);
        })
        .finally(() => {
          setPhoneModelLoading(false);
        });
    }
  }, [])

  const clearPhoneModels = useCallback(() => {
    setPhoneModels([]);
    setPhoneModel(undefined);
  }, [])

  useEffect(() => {
    if (brands?.length === 0) {
      getBrands();
    }
  }, [brands]);

  useEffect(() => {
    if (brand !== undefined) {
      getPhoneModelsByBrand(brand!.value, "");
    }
    clearPhoneModels();
    props.onBrandChanged?.(brand);
  }, [brand]);

  useEffect(() => {
    props.onModelChanged?.(phoneModel);
  }, [phoneModel]);

  return {
    form,
    brand,
    phoneModel,
    brands,
    phoneModels,
    brandLoading,
    phoneModelLoading,
    setBrand,
    setPhoneModel,
    clearPhoneModels,
    sentLeadLoading
  };
}