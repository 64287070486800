
import { createContext, useContext } from "react";
import { IGetLastOrderByUserUsecase } from "./domain/usecases/get_last_order_by_user_usecase";

const GetLastOrderByUserContext = createContext<IGetLastOrderByUserUsecase|undefined>(undefined)

export function useGetLastOrderByUserContext() {
  const context = useContext(GetLastOrderByUserContext)

  if (context === undefined) {
    throw new Error('IGetLastOrderByUserContext not found')
  }

  return context;
}

export default GetLastOrderByUserContext