import { createContext, useContext } from "react";
import { IGetUserByIdUsecase } from "./domain/usecases/get_user_by_id_usecase";

export const GetUserByIdContext = createContext<IGetUserByIdUsecase | undefined>(undefined);

export function useGetUserByIdUsecase(){
    const usecase = useContext(GetUserByIdContext);
    if(!usecase){
        throw new Error("useGetUserByIdUsecase must be used within a GetUserByIdContext.Provider");
    }
    return usecase;
}