import Footer from '../checkout_app/components/Footer'
import Header from '../checkout_app/components/Header'
import style from './style.module.css'

const Faq = () => {
    return (
        <div>
            <Header text="Perguntas Frequentes" />

            <div className={ `${style.listDetails} ${style.center}` }>
                <details open>
                    <summary>01 - O que é o Teste Fone?</summary>
                    <div>
                        <p>Apresentamos o nosso aplicativo de testes abrangente e confiável para dispositivos móveis, projetado para avaliar de forma precisa e segura os componentes, hardware e software do seu celular. Com mais de vinte testes meticulosamente elaborados, cobrimos todas as funcionalidades essenciais do seu dispositivo, garantindo que você tenha uma visão completa do seu desempenho. Maximize seu desempenho, mantenha-se informado e aproveite ao máximo todas as funcionalidades do seu celular, com confiança e segurança.</p>
                    </div>
                </details>

                <details>
                    <summary>02 - Como funcionam os testes?</summary>
                    <div>
                        <p>Nossa plataforma de testes oferece uma experiência interativa e automatizada, permitindo que você escolha entre diferentes tipos de testes, dependendo das suas necessidades e preferências. Alguns testes são interativos, permitindo que você participe ativamente e verifique diretamente o desempenho dos componentes do seu celular. Essa interação pode ser útil para testar funções específicas, como câmera, tela sensível ao toque, sensores e muito mais.</p>

                        <p>Além disso, para maior conveniência, o nosso aplicativo também possui testes automatizados, que são executados de forma rápida e eficiente, poupando o seu tempo. Esses testes abrangem aspectos como desempenho do processador, memória RAM, conectividade, duração da bateria, entre outros. Ao realizar esses testes automaticamente, você obtém informações detalhadas e confiáveis sobre o funcionamento do seu dispositivo, garantindo uma experiência otimizada.</p>
                    </div>
                </details>

                {/* <details>
                    <summary>Informações sensíveis</summary>
                    <div>
                        <p>Nossa prioridade é garantir a segurança das suas informações sensíveis. Todos os testes são conduzidos de maneira segura e não invasiva, respeitando sua privacidade e mantendo a confidencialidade dos seus dados. Nosso aplicativo adere aos mais altos padrões de segurança e proteção de dados, para que você possa realizar os testes com total tranquilidade.</p>
                    </div>
                </details> */}

                <details>
                    <summary>03 - Seguro celular</summary>
                    <div>
                        <p>O destaque do nosso aplicativo é a capacidade de atribuir uma nota ao seu celular com base nos resultados dos testes realizados. Essa nota ajuda você a compreender de forma rápida e clara o desempenho geral do seu dispositivo. Com essa informação em mãos, você pode tomar decisões mais informadas sobre o seguro de celular.</p>

                        <p>Ao buscar o seguro de celular mais aderente, o nosso aplicativo oferece uma opção única de comparação de diferentes planos de seguro disponíveis no mercado. Com base na nota atribuída ao seu celular e nas especificações do fabricante, o aplicativo identifica as opções de seguro que são mais compatíveis com o seu dispositivo, garantindo a proteção adequada para suas necessidades específicas.</p>
                    </div>
                </details>

                <details>
                    <summary>04 - Relatório</summary>
                    <div>
                        <p>Esse relatório se torna uma ferramenta valiosa quando você está buscando vender o seu celular. Ao fornecer aos potenciais compradores um documento oficial com a nota e o valor de mercado, você aumenta a confiança e a transparência na transação. Os compradores poderão avaliar objetivamente o estado do celular e o seu valor, facilitando a negociação e tornando o processo de venda mais seguro e eficiente.</p>
                    </div>
                </details>

                <details>
                    <summary>05 - Como posso contratar o meu seguro?</summary>
                    <div>
                        <p>Contratar o seu seguro é descomplicado e rápido. Ao acessar a página inicial do aplicativo, localize a opção “Cote seu seguro”. Você terá acesso as coberturas disponíveis, podendo escolher aquela que melhor atende as suas necessidades. Durante o processo, será solicitado os seu dados pessoais e o IMEI do seu aparelho.</p>
                        <p>Durante o processo, será solicitado os seu dados pessoais e o IMEI do seu aparelho.</p>
                        <p>Após a confirmação do pagamento, a apólice será enviada diretamente para o e-mail cadastrado.</p>
                    </div>
                </details>

                {/* <details>
                    <summary>Por que preciso fazer testes para contratar um seguro?</summary>
                    <div>
                        <p>Os testes buscam atestar o bom funcionamento do seu aparelho, por isso é tão importante! Lembre-se que é importante fazer todo o processo diretamente do aparelho que será protegido pela Exa.</p>
                    </div>
                </details> */}

                <details>
                    <summary>06 - A partir de que momento o meu seguro estará ativo?</summary>
                    <div>
                        <p>O seguro estará ativo após a realização dos testes e pagamento da primeira mensalidade.</p>
                    </div>
                </details>

                <details>
                    <summary>07 - Franquia e carência</summary>
                    <div>
                        <p>O valor da franquia se limita sempre ao valor de <mark>20%</mark>.</p>

                        <ul>
                            <li><strong>Roubo ou furto</strong>: <mark>20%</mark> referente ao valor do celular.</li>
                            <li><strong>Quebra</strong>: <mark>20%</mark> referente ao valor do conserto.</li>
                        </ul>
                        <p>* Lembrando que <strong>não existe carência</strong>.</p>
                    </div>
                </details>

                <details>
                    <summary>08 - Como fazer para abrir um sinistro?</summary>
                    <div>
                        <p><strong>BOLETIM DE OCORRÊNCIA</strong> <br /> Faça um boletim de roubo ou furto qualificado informando a ocorrência. Procure a Polícia Civil do seu estado.</p>
                        <p><strong>NOTA FISCAL</strong> <br /> Esteja com a Nota fiscal de seu aparelho. Se ela estiver em nome de terceiros, precisaremos da comprovação de vínculo.</p>
                        <p><strong>NÚMERO DO IMEI DO APARELHO</strong> <br /> Você consegue encontrar esse número na caixa do seu aparelho, na nota fiscal ou ainda na sua conta Google ou Conta do ID da Apple. Ainda não achou? Preparamos um post com "algumas dicas" para você.</p>
                        <p><strong>FALE COM A ANNA</strong> <br /> Com os dados do Boletim de Ocorrência e o Número do IMEI do aparelho, entre na plataforma kakau.co e abra um chamado com a Anna. Caso você não tenha o aparelho, o IMEI deve constar na Nota Fiscal.</p>
                    </div>
                </details>

                <details>
                    <summary>09 - Quanto tempo devo esperar até a análise final e pagamento de um sinistro?</summary>
                    <div>
                        <p>Após o envio de toda a documentação, o tempo limite máximo por lei de pagamento para indenização é de até 30 dias corridos. Mas faremos de tudo para fazer mais rápido. Afinal, ninguém fica sem celular hoje.</p>
                    </div>
                </details>

                <details>
                    <summary>10 - Como realizo o cancelamento do meu seguro?</summary>
                    <div>
                        <p>Para realizar a suspensão da assinatura, basta entrar em contato em WhatsApp: +55 11 96448-4049. Tudo pronto! Depois desta etapa, seu seguro estará suspenso e você não receberá mais cobranças. </p>
                        <p>ps: o cancelamento pode ser realizado a qualquer momento, sem multas ou taxas adicionais.</p>
                    </div>
                </details>
            </div>

            <Footer />
        </div>
    )
}

export default Faq