import { query } from 'dns-query';

async function domainValidator(domain: string) {

  const { answers } = await query({question: { type: 'A', name: domain }}, { endpoints: ['dns.google'] })
  const { answers: answersmx } = await query({question: { type: 'MX', name: domain }}, { endpoints: ['dns.google'] })
  const { answers: answerssoa } = await query({question: { type: 'SOA', name: domain }}, { endpoints: ['dns.google'] })
  const { answers: answerstxt } = await query({question: { type: 'TXT', name: domain }}, { endpoints: ['dns.google'] })

  if (answers?.length === 0, answersmx?.length === 0, answerssoa?.length === 0, answerstxt?.length === 0)
    return false

  return true
}

export default domainValidator;
