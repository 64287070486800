
import { Text } from "gosafe/atoms"
import React from "react"

function MyOptionsSubtitle({ phoneNumber }: { phoneNumber: string }) {
    const formattedPhoneNumber = phoneNumber.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");

    return (
        <Text fontWeight="font-normal" color='text-gray-dark'>
            Digite o código que enviamos para o número <br className="hidden md:inline"/>
            {formattedPhoneNumber} para validar as informações<br className="hidden md:inline"/>
            e garantir a sua segurança.
        </Text>
    )
}

export default React.memo(MyOptionsSubtitle);
