import style from './style.module.css'
import LOGO from './images/logo-testefone.png'
import { Link } from "react-router-dom"

const Header = ({
    // TODO: retornar texto comentado em 01/06/2024
    // text = 'Falta pouco para o seu celular estar protegido.',
    text = '1º mês é por nossa conta, este valor se refere as próximas parcelas.',
    userName = '',
    error = false,
    warning = false
}) => {
    
    window.addEventListener('beforeunload', function (e) {
        e.preventDefault();
        localStorage.removeItem('success_payment')
    });

    return (
        <>
            <header className={style.header} style={{ backgroundColor: error ? 'var(--color-secondary)' : warning && 'var(--color-tertiary)'}}>
                <div className={style.center}>
                    <Link to='/'>
                        <img src={LOGO} alt="Logo Testefone" style={{ maxWidth: '110px' }}/>
                    </Link>

                    <div>
                        {userName !== '' && <span>{userName},</span>}
                        <h1>{text}</h1>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header