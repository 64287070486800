import { IHttpClient } from "core/protocols/http_client";

import ProfileResultEntity from "./domain/entities/profile_entity";
import ProfileForm from "./presenter/profile_form";
import ProfileBindings from "./profile_bindings";
import ProfileEntity from "./domain/entities/profile_entity";
import ProfileOutputType from "./domain/types/profile_output_type";

export { ProfileResultEntity };



interface ProfileProps {
  httpClient: IHttpClient;
  onNextStep?: (profile: ProfileOutputType) => void;
  profile?: ProfileEntity
}

export default function Profile({ httpClient, onNextStep, profile }: ProfileProps) {
  return (
    <ProfileBindings httpClient={httpClient}>
      <ProfileForm onNextStep={onNextStep} profile={profile} />
    </ProfileBindings>
  );
}
