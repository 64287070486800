import { IGetPhoneInfoByOrderUsecase } from "features/purchase/domain/usecases/get_phone_info_by_order_usecase";
import { createContext, useContext } from "react";

const GetOrderContext = createContext<IGetPhoneInfoByOrderUsecase | undefined>(undefined)

export function useGetPhoneInfoByOrderContext() {
  const context = useContext(GetOrderContext);

  if (context === undefined) {
    throw new Error('IGetPhoneInfoByOrderContext not found')
  }

  return context;
}

export default GetOrderContext;