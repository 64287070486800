interface OrderSubscription {
  subscribed_at: string;
  subscribed_origin: string;
}

class OrderEntity {
  auth_id: string;
  brand_name: string;
  created_at: string;
  end_validity: string;
  id: number;
  imei: string;
  inspection_url: string;
  manual_url: string;
  model_name: string;
  msisdn: string;
  policy_number: string;
  policy_url: string;
  product_name: string;
  score: number;
  start_validity: string;
  state: string;
  subscription: OrderSubscription;
  update_at: string;

  constructor(args: {
    auth_id: string,
    brand_name: string,
    created_at: string,
    end_validity: string,
    id: number,
    imei: string,
    inspection_url: string,
    manual_url: string,
    model_name: string,
    msisdn: string,
    policy_number: string,
    policy_url: string,
    product_name: string,
    score: number,
    start_validity: string,
    state: string,
    subscription: OrderSubscription,
    update_at: string
  }) {
    this.auth_id = args.auth_id
    this.brand_name = args.brand_name
    this.created_at = args.created_at
    this.end_validity = args.end_validity
    this.id = args.id
    this.imei = args.imei
    this.inspection_url = args.inspection_url
    this.manual_url = args.manual_url
    this.model_name = args.model_name
    this.msisdn = args.msisdn
    this.policy_number = args.policy_number
    this.policy_url = args.policy_url
    this.product_name = args.product_name
    this.score = args.score
    this.start_validity = args.start_validity
    this.state = args.state
    this.subscription = args.subscription
    this.update_at = args.update_at
  }
}

export default OrderEntity;