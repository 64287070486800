import * as Sentry from "@sentry/react";
import { IHttpClient } from "core/protocols/http_client";
import { TicketEntity } from "../entities/ticket_entity";

export interface IGetTicketByUserUsecase {
  execute(type: string): Promise<TicketEntity[]>;
}

export default class GetUserByTicketUsecaseImpl implements IGetTicketByUserUsecase {
  private httpClient: IHttpClient;

  constructor(httpClient: IHttpClient) {
    this.httpClient = httpClient;
  }

  execute(type: string): Promise<TicketEntity[]> {
    return this.httpClient.get<any>(`/bff/v1/orders/?type=${type}`).then((result) => {
      if (result.data && result.data.length === 0) {
        throw new Error('Ticket not found');
      }
      return result.data.map((ticket:TicketEntity) => {
        return new TicketEntity({
          auth_uid: ticket.auth_uid,
          msisdn: ticket.msisdn,
          brand_name: ticket.brand_name,
          created_at: ticket.created_at,
          end_validity: ticket.end_validity,
          imei: ticket.imei,
          inspection_url: ticket.inspection_url,
          manual_url: ticket.manual_url,
          model_name: ticket.model_name,
          policy_number: ticket.policy_number,
          policy_url: ticket.policy_url,
          product_name: ticket.product_name,
          start_validity: ticket.start_validity,
          state: ticket.state,
          suspended_at: ticket.suspended_at,
          updated_at: ticket.updated_at,
        })
      });

    }).catch((error) => {
      Sentry.captureException(error, {
        tags: {
          'feature.purchase': "GetUserByTicketUsecaseImpl",
          'api.endpoint': `/bff/v1/orders/?type=${type}`,
        },
      });
      throw error;
    });
  }
}