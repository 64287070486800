import { Firestore, doc, getDoc } from "firebase/firestore";
import { IFeatureToggleDatasource } from "../domain/interfaces/feature_toggle_datasource";

class FeatureToggleFirestoreDatasourceImpl implements IFeatureToggleDatasource {
    private static instance: FeatureToggleFirestoreDatasourceImpl;
    private db: Firestore;
    private configs?: any;

    private constructor(db: Firestore) {
        this.db = db;
    }

    public static getInstance(db: Firestore): FeatureToggleFirestoreDatasourceImpl {
        if (!FeatureToggleFirestoreDatasourceImpl.instance) {
            FeatureToggleFirestoreDatasourceImpl.instance = new FeatureToggleFirestoreDatasourceImpl(db);
        }
        return FeatureToggleFirestoreDatasourceImpl.instance;
    }

    private async loadConfigs(): Promise<void> {
        try {
            const docRef = doc(this.db, 'web_config', 'all_config');
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                this.configs = docSnap.data() as { [key: string]: boolean };
            } else {
                this.configs = {};
            }
        } catch (error) {
            throw error;
        }
    }

    async getValue(key: string): Promise<boolean> {
        try {
            if (!this.configs) {
                await this.loadConfigs();
            }

            return this.configs[key] ?? false;
        } catch (error) {
            throw error;
        }
    }
}

export default FeatureToggleFirestoreDatasourceImpl;
