import { Profile } from "features";
import { CustomerEntity } from "features/purchase/domain/entities/customer_entity";
import { IHttpClient } from "core/protocols/http_client";
import ProfileOutputType from "features/profile/domain/types/profile_output_type";

interface StepProfileFormProps {
    httpClient: IHttpClient;
    customer?: CustomerEntity;
    onNextStep: (profile: ProfileOutputType) => void;
}

function StepProfileForm({ httpClient, customer, onNextStep }: StepProfileFormProps) {
    
    return (<Profile
        httpClient={httpClient}
        onNextStep={onNextStep}
        profile={
            customer ?
                {
                    cpf: customer?.cpf ?? "",
                    phone: customer?.phone ?? "",
                    birthday: customer?.birthday ?? "",
                    fullname: customer?.fullname ?? "",
                    address: {
                        cep: customer?.address?.zipcode ?? "",
                        logradouro: customer?.address?.name ?? "",
                        numero: customer?.address?.number ?? "",
                        complemento: customer?.address?.complement ?? "",
                        bairro: customer?.address?.neighborhood ?? "",
                        localidade: customer?.address?.city ?? "",
                        uf: customer?.address?.uf ?? "",
                    }
                } : undefined
        } />)

}

export default StepProfileForm;