import PaymentInProcessingSection from "features/checkout/presenter/componentes/payment-in-processing-section";

interface StepProcessPaymentProps {
    onRefresh: () => void
}

function StepProcessPayment({ onRefresh }: StepProcessPaymentProps) {
    return (
        <div className="content-center pb-8">
            <div className="flex max-w-[1100px] flex-col gap-0 md:flex-row md:gap-40">
                <PaymentInProcessingSection onRefresh={onRefresh}/>
            </div>
        </div>
    );
}

export default StepProcessPayment;