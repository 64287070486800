import { IHttpClient } from "core/protocols/http_client";
import GetOrderUsecaseImpl from "features/purchase/domain/usecases/get_phone_info_by_order_usecase";
import GetUserByIdUsecaseImpl from "features/purchase/domain/usecases/get_user_by_id_usecase";
import GetLastOrderByUserContext from "features/purchase/get_last_order_by_user_context";
import GetPhoneByOrderContext from "features/purchase/get_phone_info_by_order_context";
import { ReactNode } from "react";
import GetLastOrderByUserUsecaseImpl from "../domain/usecases/get_last_order_by_user_usecase";
import { GetUserByIdContext } from "../get_user_by_id_context";

interface PurchaseBindingsProps {
  children: ReactNode;
  httpClient: IHttpClient;
}

export default function PurchaseBinding({children, httpClient}: PurchaseBindingsProps){
  return (
    <>
    <GetUserByIdContext.Provider value={new GetUserByIdUsecaseImpl(httpClient)}>
      <GetLastOrderByUserContext.Provider value={new GetLastOrderByUserUsecaseImpl(httpClient)}>
        <GetPhoneByOrderContext.Provider
          value={new GetOrderUsecaseImpl(httpClient)}
        >
          {children}
        </GetPhoneByOrderContext.Provider>
      </GetLastOrderByUserContext.Provider>
    </GetUserByIdContext.Provider>

    </>
  )
}