import { IHttpClient } from "core/protocols/http_client";
import { CustomerEntity } from "../entities/customer_entity";
import * as Sentry from "@sentry/react";
export interface IGetUserByIdUsecase {
  execute(id: string): Promise<CustomerEntity>;
}


export default class GetUserByIdUsecaseImpl implements IGetUserByIdUsecase {
  private httpClient: IHttpClient;

  constructor(httpClient: IHttpClient) {
    this.httpClient = httpClient;
  }

  execute(id: string): Promise<CustomerEntity> {
    return this.httpClient.get<any>(`/bff/v1/user`).then((result) => {

      const user = result.data;
      const customer = user.customer;
      let msisdns = [];

      if (user.msisdn_list) {
        msisdns = user.msisdn_list.map((msisdn: any) => {
          return {
            msisdn: msisdn.msisdn,
            validated: msisdn.validated,
            validatedAt: msisdn.validated_at
          }
        });
      }
      return new CustomerEntity({
        birthday: user.birthday,
        cpf: user.cpf,
        phone: user.phone,
        msisdns: msisdns,
        have_ticket: user.have_ticket,
        fullname: user.fullName,
        address: customer ? {
          city: customer.address.city,
          complement: customer.address.complement,
          name: customer.address.name,
          neighborhood: customer.address.neighborhood,
          number: customer.address.number,
          zipcode: customer.address.zipcode,
          uf: customer.address.state
        } : {
          city: '',
          complement: '',
          name: '',
          neighborhood: '',
          number: '',
          zipcode: '',
          uf: ''
        }
      })
    }).catch((error) => {
      Sentry.captureException(error, {
        tags: {
          'feature.purchase': "GetUserByIdUsecaseImpl",
          'api.endpoint': `/bff/v1/user`,
        },
      });
      throw error;
    });
  }
}