import {ReactNode} from "react";
import GetShortUrlUseCase from "../../domain/usecases/get_short_url_usecase";
import {GetShortUrlContext} from "../../get_short_url_context";
import {useHttpClient} from "../../../../bindings/http_binding";

interface QrCodeBindingsProps {
  children: ReactNode;
}

export default function QrCodeBinding({children}: QrCodeBindingsProps) {
  const httpClient = useHttpClient();
  
  return(
    <GetShortUrlContext.Provider value={new GetShortUrlUseCase(httpClient)}>
      {children}
    </GetShortUrlContext.Provider>
  )
}