import { ReactNode } from "react";

import { IHttpClient } from "core/protocols/http_client";

import SendLeadUsecaseImpl from "features/brand_and_model_search/domain/usecases/update_lead_usecase";
import SendLeadContext from "features/brand_and_model_search/send_lead_context";
import {
  GetBrandsByNameUsecaseImpl,
  GetModelsByBrandAndNameUsecaseImpl,
} from "./domain";
import GetBrandsByNameContext from "./get_brands_by_name_context";
import GetModelsByBrandAndNameContext from "./get_models_by_brand_and_name_context";
import UpdateLeadContext from "./send_lead_context";
import UpdateLeadUsecaseImpl from "./domain/usecases/update_lead_usecase";

interface BrandAndModelSearchBindingsProps {
  children: ReactNode;
  httpClient: IHttpClient;
}

export default function BrandAndModelSearchBindings({
  children,
  httpClient,
}: BrandAndModelSearchBindingsProps) {
  return (
    <>
      <GetBrandsByNameContext.Provider
        value={new GetBrandsByNameUsecaseImpl(httpClient)}
      >
        <GetModelsByBrandAndNameContext.Provider
          value={new GetModelsByBrandAndNameUsecaseImpl(httpClient)}
        >
          <UpdateLeadContext.Provider value={new UpdateLeadUsecaseImpl(httpClient)}>
            {children}
          </UpdateLeadContext.Provider>
        </GetModelsByBrandAndNameContext.Provider>
      </GetBrandsByNameContext.Provider>
    </>
  );
}
