import { IHttpClient } from "core/protocols/http_client";

import ImeiCheckEntity from "../entities/imei_check_entity";
import ImeiCheckResultEntity from "../entities/imei_check_result_entity";
import * as Sentry from "@sentry/react";

export interface IImeiCheckUsecase {
  execute(imeiCheckEntity: ImeiCheckEntity): Promise<ImeiCheckResultEntity>;
}

export default class ImeiCheckUsecaseImpl implements IImeiCheckUsecase {
  private httpClient: IHttpClient;

  constructor(httpClient: IHttpClient) {
    this.httpClient = httpClient;
  }

    async execute(entity: ImeiCheckEntity): Promise<ImeiCheckResultEntity> {
        return this.httpClient.post<any>(`/bff/v1/imei/check`, {
            name: entity.name,
            brand: entity.brand,
            model:  entity.identifiers,
            imei: entity.imei.replace(/\D/g, '')
        }).then(async response => {
            const item = response.data;
            return new ImeiCheckResultEntity(
                item.imei,
                item.brand,
                item.model,
                item.name,
                item.is_valid,
            );
        }).catch(error => {
          Sentry.captureException(error, {
            tags: {
              'feature.imei_check': true,
              'api.endpoint': '/bff/v1/imei/check',
            },
            extra: {
              imeiCheckEntity: {
                name: entity.name,
                brand: entity.brand,
                model: entity.identifiers,
                imei: entity.imei.replace(/\D/g, ''),
              },
            },
          });
            throw error
        });
    }
}
