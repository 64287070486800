import { Button } from "gosafe/molecules/buttons";

function ButtonResendOption({id, onClick, text }: { id?: string, onClick: () => void, text: string, icon: JSX.Element }) {
    return (
        <div className="min-w-[320px] text-left">
            <Button
                id={id}
                onClick={onClick}
                color="text-neutral-40"
                padding="pa-0"
                showIconRight={true}
                showIconLeft={true}
                backgroundColor='bg-transparent'
                iconLeft={<div><u>{text}</u></div>}
                infinity={false}
                borderRadius="rounded-3xl"
            >
            </Button>
        </div>
    )
}
export default ButtonResendOption;