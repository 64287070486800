import { Divider } from "gosafe/atoms";
import { DotStepper } from "gosafe/molecules";
import { BackButton } from "gosafe/molecules/buttons";
import MyOptions, { MyOptionsTitle } from "../my_options";
import Checkout from "features/checkout/";
import { PhoneEntity } from "features/brand_and_model_search";
import { ProductModel } from "models";

interface StepCreditCardFormProps {
    phoneNumber?: string;
    product?: ProductModel;
    imei?: string;
    onSuccess: () => void;
    onError: () => void;
}

function StepCreditCardForm({ phoneNumber, product, imei, onSuccess, onError }: StepCreditCardFormProps) {

    return (
        <Checkout
            phoneNumber={phoneNumber}
            product={product}
            imei={imei}
            onSuccess={onSuccess}
            onError={onError} />
    )
}
export default StepCreditCardForm;