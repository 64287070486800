import { endpoint } from "constants/endpoints";
import { IHttpClient } from "core/protocols/http_client";
import * as Sentry from "@sentry/react";

export class Lead {
  model: string;
  manufacturer: string;
  email: string;
  opt_in: boolean;

  constructor(
    manufacturer: string,
    model: string,
    email: string,
    opt_in: boolean
  ){
    this.manufacturer = manufacturer;
    this.model = model;
    this.email = email;
    this.opt_in = opt_in;
  }

}

export interface IUpdateLeadUsecase {
  execute(lead: Lead): Promise<UpdateLeadResponse>
}

interface UpdateLeadResponse {
  message: string, 
  detail?: unknown
}

export default class UpdateLeadUsecaseImpl implements IUpdateLeadUsecase{
  private httpClient: IHttpClient;

  constructor(httpClient: IHttpClient) {
    this.httpClient = httpClient;
  }

  async execute(lead: Lead): Promise<UpdateLeadResponse> {
    try {
      const response = await this.httpClient.post<UpdateLeadResponse>(endpoint.lead.quote, lead)
      return response.data;
    } catch(error){
      Sentry.captureException(error, {
        tags: {
          'feature.brand_and_model_search': "UpdateLeadUsecaseImpl",
          'api.endpoint': endpoint.lead.quote,
        },
      });
      throw error;
    }
  }
}
